import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loading from './Loading'
import { UserAuth } from '../AuthContext'
import { createOrder } from '../../API/root'
import axios from 'axios'
import _ from 'lodash'

const PaymentModal = ({ onClose, finalOrders, totalCost }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const root_user = useSelector((state) => state.root_user)

  const [isLoading, setIsLoading] = useState(false)
  const { token, user } = UserAuth()

  const onClickMakePayment = async () => {
    const data = {
      modules: finalOrders,
    }
    setIsLoading(true)
    const response = await createOrder({ data, token })
    if (_.isEmpty(response)) {
      setIsLoading(false)
      onClose()
      return
    }

    const { order, key } = response

    const options = {
      key,
      amount: order.amount,
      currency: 'INR',
      name: 'QueueRelief',
      description: 'Payment for printout',
      image: 'https://queuerelief.com/logo-primary.png',
      order_id: order.id,
      handler: async (response) => {
        try {
          setIsLoading(true)
          const url = `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL}/paymentVerifiedUI`
          const { data } = await axios.post(url, response)
          if (data['razorpay_order_id']) {
            const { razorpay_order_id, token, otp } = data
            let navigate_url = `?order_id=${razorpay_order_id}`
            if (token && otp) {
              navigate_url = `?order_id=${razorpay_order_id}&token=${token}&otp=${otp}`
            }
            navigate({
              pathname: '/order-created',
              search: navigate_url,
            })
          } else {
            setIsLoading(false)
          }
        } catch (e) {
          setIsLoading(false)
        }
      },
      theme: {
        color: '#546de5',
      },
      prefill: {
        email: user['email'],
        contact: root_user['phone'],
      },
    }
    const razor = new window.Razorpay(options)
    razor.open()
    setIsLoading(false)
  }

  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      {!isLoading ? (
        <div className="bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
          <div>
            <div className="text-xl text-onSurface font-semibold mb-6">
              Confirm
            </div>
            <div className="mt-4 font-semibold bg-warningBackground text-warning px-4 py-2 rounded-md mb-4">
              Please do not close this window after payment. This page will
              automatically redirect.
            </div>
            <p className="text-onBackground">
              Pickup location is always at the photocopy shop from where you
              ordered.
            </p>
            <div className="mt-8 mb-2">
              <button
                className="border border-onBackground text-onBackground px-8 py-3 mr-4 rounded-md
                hover:bg-surface"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="border border-primary text-onPrimary bg-primary px-8 py-3 rounded-md
                hover:bg-primaryDark hover:border-primaryDark"
                onClick={onClickMakePayment}
              >
                Make Payment
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}
export default PaymentModal
