import React, { useState } from 'react'
import {
  IoTabletLandscapeOutline,
  IoTabletPortraitOutline,
} from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { HiOutlineDocumentDuplicate, HiOutlineDocument } from 'react-icons/hi'
import { openDocument } from './partials/AdminDocumentItem'
import { IoLinkOutline } from 'react-icons/io5'

const CustomDocumentCustomization = ({ doc, setCustomDocument }) => {
  const dispatch = useDispatch()
  const { no_of_pages } = doc
  const [page_size, setPaperSize] = useState(
    doc['page_size'] ? doc['page_size'] : 'A4'
  )
  const [quantity, setQuantity] = useState(doc['quantity'])
  const [orientation, setOrientation] = useState(doc['orientation'])
  const [print_type, setPrintType] = useState(doc['print_type'])
  const [print_style, setPrintStyle] = useState(
    doc['print_style'] ? doc['print_style'] : 'single'
  )

  const onConfirm = () => {
    dispatch({
      type: 'UPDATE_CART_ITEM',
      key: doc.key,
      data: {
        page_size,
        quantity,
        orientation,
        print_type,
        print_style,
      },
    })
    setCustomDocument(null)
  }

  const shop = doc['shop']

  let bw_p_cost = 0,
    color_p_cost = 0
  // if (shop) {
  //   const { pricing } = shop
  //   const range_1 =
  //     print_style == 'b2b'
  //       ? pricing['bw_printout_cost_b2b']
  //       : pricing['A4_bw_printout_cost']
  //   const range_2 =
  //     print_style == 'b2b'
  //       ? pricing['color_printout_cost_b2b']
  //       : pricing['color_printout_cost']
  //   bw_p_cost = getCostFromRange({ range: range_1, no_of_pages })
  //   color_p_cost = getCostFromRange({ range: range_2, no_of_pages })
  // }

  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="bg-background py-8 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        <div
          className="flex items-center gap-x-2 font-semibold text-lg pb-4 px-6 underline hover:cursor-pointer overflow-hidden"
          onClick={() => openDocument(doc['key'])}
        >
          <div>
            <IoLinkOutline />
          </div>
          <div className="break-words overflow-hidden">{doc['name']}</div>
        </div>
        <div
          className={`px-6 pb-4 overflow-y-auto ${
            no_of_pages > 1 ? 'h-screen-2' : ''
          }`}
        >
          {no_of_pages > 1 ? (
            <div className="mt-2 mx-4 py-4 border-b border-b-onBackgroundDull">
              <div className="text-semibold">Choose print style</div>
              <div className="mt-4 flex items-center">
                <div className="flex flex-col items-center w-1/3">
                  <div
                    className={`hover:cursor-pointer w-12 h-12 flex justify-center items-center
          border rounded-md ${
            print_style == 'b2b'
              ? 'bg-primaryDull border-primary'
              : 'bg-background border-onBackgroundDull'
          }`}
                    onClick={() => setPrintStyle('b2b')}
                  >
                    <HiOutlineDocumentDuplicate className="text-xl" />
                  </div>
                  <div
                    className={`mt-2 text-sm font-semibold ${
                      print_style == 'b2b'
                        ? 'text-onSurface'
                        : 'text-onBackground'
                    }`}
                  >
                    Back to Back
                  </div>
                </div>
                <div className="flex flex-col items-center w-1/3">
                  <div
                    className={`hover:cursor-pointer w-12 h-12 flex justify-center items-center
          border rounded-md ${
            print_style == 'single'
              ? 'bg-primaryDull border-primary'
              : 'bg-background border-onBackgroundDull'
          }`}
                    onClick={() => setPrintStyle('single')}
                  >
                    <HiOutlineDocument className="text-xl" />
                  </div>
                  <div
                    className={`mt-2 text-sm font-semibold ${
                      print_style == 'single'
                        ? 'text-onSurface'
                        : 'text-onBackground'
                    }`}
                  >
                    Single Side
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="mt-2 mx-4 py-4 border-b border-b-onBackgroundDull">
            <div className="text-semibold">Choose print color</div>
            <div className="mt-4 flex items-center">
              <div className="flex flex-col items-center w-1/3">
                <div
                  className={`hover:cursor-pointer w-12 h-12 flex justify-center items-center
          border rounded-md ${
            print_type == 'bw'
              ? 'bg-primaryDull border-primary'
              : 'bg-background border-onBackgroundDull'
          }`}
                  onClick={() => setPrintType('bw')}
                >
                  <img src="bw.png" className="w-6" />
                </div>
                <div
                  className={`mt-2 flex flex-col items-center text-sm font-semibold ${
                    print_type == 'bw' ? 'text-onSurface' : 'text-onBackground'
                  }`}
                >
                  B&W
                  {/* {shop ? (
                    <div className="text-sm">
                      ₹{bw_p_cost}
                      /page
                    </div>
                  ) : null} */}
                </div>
              </div>
              <div className="flex flex-col items-center w-1/3">
                <div
                  className={`hover:cursor-pointer w-12 h-12 flex justify-center items-center
          border rounded-md ${
            print_type == 'color'
              ? 'bg-primaryDull border-primary'
              : 'bg-background border-onBackgroundDull'
          }`}
                  onClick={() => setPrintType('color')}
                >
                  <img src="color.png" className="w-6" />
                </div>
                <div
                  className={`mt-2 flex flex-col items-center text-sm font-semibold ${
                    print_type == 'color'
                      ? 'text-onSurface'
                      : 'text-onBackground'
                  }`}
                >
                  Color
                  {/* {shop ? (
                    <div className="text-sm">
                      ₹{color_p_cost}
                      /page
                    </div>
                  ) : null} */}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 mx-4 py-4 border-b border-b-onBackgroundDull">
            <div className="text-semibold">Choose page size</div>
            <div className="mt-4 flex items-center">
              <div className="flex flex-col items-center w-1/3">
                <div
                  className={`hover:cursor-pointer w-12 h-12 flex justify-center items-center
          border rounded-md ${
            page_size == 'A4'
              ? 'bg-primaryDull border-primary'
              : 'bg-background border-onBackgroundDull'
          }`}
                  onClick={() => setPaperSize('A4')}
                >
                  A4
                </div>
              </div>
              <div className="flex flex-col items-center w-1/3">
                <div
                  className={`hover:cursor-pointer w-12 h-12 flex justify-center items-center
          border rounded-md ${
            page_size == 'A3'
              ? 'bg-primaryDull border-primary'
              : 'bg-background border-onBackgroundDull'
          }`}
                  onClick={() => setPaperSize('A3')}
                >
                  A3
                </div>
              </div>
            </div>
          </div>
          <div className="mt-2 mx-4 py-4">
            <div className="text-semibold">Choose print orientation</div>
            <div className="mt-4 flex items-center">
              <div className="flex flex-col items-center w-1/3">
                <div
                  className={`hover:cursor-pointer w-12 h-12 flex justify-center items-center
          border rounded-md ${
            orientation == 'portrait'
              ? 'bg-primaryDull border-primary'
              : 'bg-background border-onBackgroundDull'
          }`}
                  onClick={() => setOrientation('portrait')}
                >
                  <IoTabletPortraitOutline className="text-xl" />
                </div>
                <div
                  className={`mt-2 text-sm font-semibold ${
                    orientation == 'portrait'
                      ? 'text-onSurface'
                      : 'text-onBackground'
                  }`}
                >
                  Portrait
                </div>
              </div>
              <div className="flex flex-col items-center w-1/3">
                <div
                  className={`hover:cursor-pointer w-12 h-12 flex justify-center items-center
          border rounded-md ${
            orientation == 'landscape'
              ? 'bg-primaryDull border-primary'
              : 'bg-background border-onBackgroundDull'
          }`}
                  onClick={() => setOrientation('landscape')}
                >
                  <IoTabletLandscapeOutline className="text-xl" />
                </div>
                <div
                  className={`mt-2 text-sm font-semibold ${
                    orientation == 'landscape'
                      ? 'text-onSurface'
                      : 'text-onBackground'
                  }`}
                >
                  Landscape
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center pt-6 px-6 border-t border-t-surface">
          <button
            className="w-full bg-primary text-onPrimary rounded-md py-4 text-center hover:bg-primaryDark"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  )
}

export default CustomDocumentCustomization
