import React from 'react'

export default function Footer() {
  return (
    <div className="bg-[#cef4de] text-onSurface p-3 py-10">
      <div className="flex flex-1 md:pb-4 flex-col md:flex-row md:justify-center md:items-center">
        <div className="flex flex-1 justify-center items-center">
          <ul className="flex flex-1 flex-col justify-center items-center ">
            <li className="pb-1 md:pr-10">
              <a
                href="privacy.html"
                aria-label="Privacy Policy"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>
            <li className="pb-1 md:pr-10">
              <a
                href="terms.html"
                aria-label="Terms of Service"
                target="_blank"
              >
                Terms of Service
              </a>
            </li>
            <li className="pb-1 md:pr-10">
              <a
                href="refund.html"
                aria-label="Cancellation/ Refund Policy"
                target="_blank"
              >
                Cancellation/ Refund Policy
              </a>
            </li>
          </ul>
        </div>
        <div className="flex flex-1 flex-col pt-10 pb-10 md:pt-0 md:pb-0 justify-center items-center">
          <ul className="flex flex-1 flex-col justify-center items-center ">
            <li className="pb-1 md:pr-10">
              <a href="about.html" aria-label="About us" target="_blank">
                About us
              </a>
            </li>
            <li className="pb-1 md:pr-10">
              {/* <a href="mailto:support@queuerelief.com" aria-label="Contact us"> */}
              <a href="contact.html" aria-label="Contact us" target="_blank">
                Contact us
              </a>
            </li>
          </ul>
          {/* <div>Follow us on</div> */}
          {/* <div className="flex">
            <a
              href="https://x.com/QueueRelief"
              target="_blank"
              aria-label="Twitter"
            >
              <div className="socialMediaLogo p-4">
                <FaXTwitter />
              </div>
            </a>
          </div> */}
        </div>
      </div>
      <div className="text-center text-sm">
        Copyright ©2024 FLUXIT PRIVATE LIMITED
      </div>
    </div>
  )
}
