import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { getDateForOrder, renderAmount } from '../../utils/helper'
import { FiChevronRight, FiX } from 'react-icons/fi'

const renderPath = (group_by) => {
  const path = group_by.split(' • ')
  const path_len = _.size(path)
  if (path_len == 0 || _.isEqual(path, [''])) {
    return null
  }
  return (
    <div className="px-4 py-1 border border-onBackgroundDull inline-block rounded-md">
      {_.map(path, (p, i) => (
        <div className="inline-flex flex flex-row items-center py-1" key={i}>
          {p}
          {i != path_len - 1 ? <FiChevronRight className="mx-2" /> : null}
        </div>
      ))}
    </div>
  )
}

export const getDocumentURL = (key) => {
  const url = `https://queuerelief-documents.s3.ap-south-1.amazonaws.com/${key}`
  return url
}

export const openDocument = (key) => {
  const url = getDocumentURL(key)
  window.open(url, '_blank')
}

const rendermodule = (modules) => {
  const no_of_modules = _.size(modules)
  return _.map(modules, (module, moduleIndex) => {
    const isLast = moduleIndex == no_of_modules - 1 ? true : false
    const moduleName = module['name']
    const quantityColorClass =
      module.quantity > 1 ? 'font-semibold text-secondary' : 'text-onSurface'
    return (
      <div
        className={`hover:bg-surface hover:cursor-pointer px-4 py-2  ${
          !isLast ? 'border-b border-b-surface' : ''
        }`}
        onClick={() => openDocument(module['s3_key'])}
        key={moduleIndex}
      >
        <div className="flex flex-row w-full" key={moduleIndex}>
          <div className="flex flex-row justify-between w-4/5 mr-4 rounded-md">
            <div className="flex flex-row items-center w-full">
              •
              <div className=" ml-2 py-1 flex flex-1 whitespace-normal w-full overflow-hidden">
                <div className="break-words overflow-hidden hover:cursor-pointer underline font-semibold">
                  {moduleName}
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="ml-4">
                <FiX />
              </div>
              <div className={`ml-4 py-1 ${quantityColorClass}`}>
                {module.quantity}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center mx-2 my-1">
          {module['print_type'] == 'color' ? (
            <div className="mx-2 bg-secondary text-onSecondary rounded-full px-2 py-1 text-sm">
              Color
            </div>
          ) : null}
          {module['print_style'] == 'b2b' ? (
            <div className="mx-2 bg-onBackground text-background rounded-md px-2 py-1 text-sm">
              Back to Back
            </div>
          ) : module['print_style'] != 'b2b' && module['no_of_pages'] > 1 ? (
            <div className="mx-2 bg-onBackground text-background rounded-md px-2 py-1 text-sm">
              Single Side
            </div>
          ) : null}
          {module['orientation'] == 'landscape' ? (
            <div className="mx-2 bg-onBackground text-background rounded-md px-2 py-1 text-sm">
              Landscape
            </div>
          ) : null}
          {module['page_size'] && module['page_size'] != 'A4' ? (
            <div className="mx-2 bg-onBackgroundDull text-onSurface rounded-md px-2 py-1 text-xl">
              {module['page_size']}
            </div>
          ) : null}
        </div>
      </div>
    )
  })
}

const AdminDocumentItem = ({
  order,
  index,
  buttonText,
  current_date,
  onClickOrderStatus,
  isLast,
}) => {
  const status = order['status']
  const status_color =
    status == 'PENDING' ? 'text-onBackground' : 'text-success'
  const order_date = getDateForOrder(order.created_date_timestamp)

  const border_radius = isLast ? 'rounded-b-md' : ''

  const razorpay_order_id = order.razorpay_order_id.replace('order_', '')
  let token = order['token']

  // const current_year = current_date.slice(6)

  // const token_year = token ? token.slice(4, 6) : null
  // if (token && _.isEqual(token_year, current_year)) {
  //   token = token.slice(0, 4) + token.slice(6)
  // }

  return (
    <div className="mx-10 flex flex-row" key={razorpay_order_id}>
      <div
        className={`border-b border-r border-l border-onBackgroundDull
                flex flex-col-reverse
                md:flex-row md:justify-between
                w-full inline-block p-6 ${border_radius}`}
      >
        <div className="flex flex-row gap-4 w-full">
          <div className="w-1/6">
            <div className="font-semibold">{razorpay_order_id}</div>
            <div className="font-semibold text-primary mt-1">{token}</div>
            <div className="text-onBackground mt-3 text-sm">
              {order.phone ? (
                <div className="mb-1">+91 {order.phone}</div>
              ) : null}
              <div>{order.email}</div>
            </div>
          </div>

          <div className="w-1/2">{rendermodule(order.modules)}</div>
          <div className="w-1/6">
            {buttonText ? (
              <div
                className="px-4 py-2 rounded-md bg-primary text-onPrimary border border-primary
                uppercase tracking-wide cursor-pointer inline-block hover:bg-primaryDark hover:border-primaryDark"
                onClick={() => onClickOrderStatus(order)}
              >
                {buttonText}
              </div>
            ) : null}
          </div>
          <div className="w-1/6">
            <div className="text-2xl font-semibold whitespace-nowrap">
              {renderAmount(order.amount)}
            </div>
            <div className="mt-4 text-onBackground text-sm">
              {order_date ? (
                <>
                  {current_date !== order_date ? (
                    <span>
                      {order_date} at{' '}
                      {moment(order.created_date_timestamp).format('h:mm a')}
                    </span>
                  ) : null}
                </>
              ) : (
                <>{moment(order.created_date_timestamp).format('h:mm a')}</>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AdminDocumentItem)
