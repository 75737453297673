import React from 'react'
import { openDocument } from './AdminDocumentItem'
import { IoLinkOutline } from 'react-icons/io5'

const CreatedOrderCustomizationViewModal = ({
  selected_document,
  setSelectedDocument,
}) => {
  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        <div>
          <div
            className="flex items-center gap-x-2 font-semibold text-lg overflow-hidden"
            onClick={() => {
              if (selected_document['order_status'] != 'RECEIVED') {
                openDocument(selected_document['s3_key'])
              }
            }}
          >
            {selected_document['order_status'] != 'RECEIVED' ? (
              <div>
                <IoLinkOutline />
              </div>
            ) : null}
            <div
              className={`break-words overflow-hidden font-semibold text-lg pb-1 ${
                selected_document['order_status'] != 'RECEIVED'
                  ? 'underline hover:cursor-pointer'
                  : ''
              }`}
            >
              {selected_document['name']}
            </div>
          </div>
          <div className="mt-4 flex items-center gap-x-4">
            {selected_document['print_type'] == 'bw' ? (
              <img src="bw.png" className="w-8" />
            ) : (
              <img src="color.png" className="w-8" />
            )}
            {selected_document['print_style'] == 'b2b' ? (
              <div className="mx-2 bg-onBackground text-background rounded-md px-2 py-1 text-sm">
                Back to Back
              </div>
            ) : selected_document['print_style'] != 'b2b' &&
              selected_document['no_of_pages'] > 1 ? (
              <div className="mx-2 bg-onBackground text-background rounded-md px-2 py-1 text-sm">
                Single Side
              </div>
            ) : null}
            {selected_document['orientation'] == 'landscape' ? (
              <div className="mx-2 bg-onBackground text-background rounded-md px-2 py-1 text-sm">
                Landscape
              </div>
            ) : null}
            {selected_document['page_size'] ? (
              <div className="mx-2 bg-onBackgroundDull text-onSurface rounded-md px-2 py-1">
                {selected_document['page_size']}
              </div>
            ) : null}
          </div>
          <button
            className="w-full border border-onBackground rounded-md py-4 mt-8 text-center hover:bg-surface"
            onClick={() => setSelectedDocument(null)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreatedOrderCustomizationViewModal
