import React, { useState, useEffect } from 'react'
import UploadCustomDocuments from './partials/UploadCustomDocuments'
import { useNavigate } from 'react-router-dom'
import UploadingFilesModal from './partials/UploadingFilesModal'
import DiscountModal from './partials/DiscountModal'

const Options = () => {
  const navigate = useNavigate()
  const [uploading, setUploading] = useState(false)

  const [show_discount, setShowDiscount] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('discount_shown') != 'true') {
      setShowDiscount(true)
    }
  }, [])

  return (
    <div className="mt-24 lg:-mt-10 ">
      <>
        <UploadCustomDocuments setUploading={setUploading} />
        {/* <div
          className="mt-6 flex space-x-4 mx-4 border border-onBackgroundDull rounded-md p-4
                hover:bg-primaryDull hover:cursor-pointer"
          onClick={() => navigate('/shops')}
        >
          <div className="-mt-1">
            <div className="font-semibold text-lg">Print shop documents</div>
            <div className="mt-2 text-sm text-onBackground">
              These are the documents that already exists in the shop
            </div>
          </div>
        </div> */}
      </>
      {uploading ? <UploadingFilesModal /> : null}
      {show_discount ? (
        <DiscountModal setShowDiscount={setShowDiscount} />
      ) : null}
    </div>
  )
}

export default Options
