import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getStatisticsData } from '../API/root'
import { UserAuth } from './AuthContext'
import Loading from './partials/Loading'
import _ from 'lodash'
import BarChart from './partials/BarChart'
import TopStats from './partials/TopStats'
import StatisticsDate from './partials/StatisticsDate'
import { showToast } from '../utils/helper'

const Statistics = () => {
  const user = useSelector((state) => state.root_user)
  const [stats, setStats] = useState({})
  const { token } = UserAuth()
  const dispatch = useDispatch()

  const getData = async () => {
    showToast({
      message: 'Fetching latest analytics...',
      background_color: '#ededed',
      color: '#333333',
    })
    const data_temp = await getStatisticsData({
      shop_id: user.admin_at,
      token,
    })
    setStats(data_temp)
    // dispatch({ type: 'STATS', data: data_temp })
    if (!_.isEmpty(data_temp)) {
      showToast({
        message: 'Analytics fetched',
        background_color: '#b2d9b2',
        color: '#2a472a',
        autoClose: 2500,
      })
    }
  }
  useEffect(() => {
    getData()
  }, [token])

  if (_.isEmpty(stats)) {
    return <Loading />
  }

  return (
    <div className="mt-20 mx-10">
      <TopStats stats={stats} />
      <div className="flex flex-col-reverse lg:flex-row gap-10">
        <BarChart stats={stats} />
        <StatisticsDate />
      </div>
    </div>
  )
}

export default Statistics
