import React from 'react'

const OrderReceivedConfirmModal = ({ onClose, onConfirm, order }) => {
  const razorpay_order_id = order?.razorpay_order_id.replace('order_', '')
  const token = order['token']
  const otp = order['otp']
  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="flex items-center justify-center">
        <div className="bg-background mt-40 py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-1/2 xl:w-1/3 2xl:1/4">
          <div>
            <div className="text-xl text-onSurface font-semibold mb-6">
              Confirm
            </div>
            <div className="mb-4 mr-6 flex items-center">
              <div
                className="inline-block bg-surface rounded-md px-4 py-3 border border-surface"
                placeholder="Order ID"
              >
                {razorpay_order_id}
              </div>
              <div
                className="inline-flex items-center mx-4 text-md border-dashed
        border border-onSurface inline-block self-start rounded-md px-4 py-3"
              >
                <div
                  className="font-semibold text-primary md:hiddenself-start
                  pr-4 border-r border-r-onBackground"
                >
                  {token}
                </div>

                <div className="pl-4 font-semibold text-secondary md:hiddenself-start">
                  {otp}
                </div>
              </div>
            </div>
            <p className="text-onBackground">
              Please confirm that the printout has been received
            </p>
            <div className="mt-8 mb-2">
              <button
                className="border border-onBackground text-onBackground px-8 py-3 mr-4 rounded-md
                hover:bg-surface"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="border border-primary text-onPrimary bg-primary px-8 py-3 rounded-md
                hover:bg-primaryDark hover:border-primaryDark"
                onClick={onConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderReceivedConfirmModal
