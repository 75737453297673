const CustomTextInput = ({
  type,
  title,
  value,
  optional,
  placeholer,
  onChange,
  maxLength,
  long,
}) => {
  return (
    <div className="mb-6">
      <div className="text-primary text-sm mb-2">
        {title}
        {optional ? (
          <span className="text-onBackground ml-2">(Optional)</span>
        ) : null}
      </div>
      {long ? (
        <textarea
          type="text"
          value={value}
          placeholder={placeholer}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength}
          className="px-4 py-2 rounded-md border border-onBackgroundDull bg-background
                    focus:outline-none focus:border-primary text-onSurface w-full md:w-1/3 lg:w-1/4 h-32"
        />
      ) : (
        <input
          type={type ? type : 'text'}
          value={value}
          placeholder={placeholer}
          onChange={(e) => onChange(e.target.value)}
          maxLength={maxLength}
          className="px-4 py-2 rounded-md border border-onBackgroundDull bg-background
                    focus:outline-none focus:border-primary text-onSurface w-full md:w-1/3 lg:w-1/4"
        />
      )}
    </div>
  )
}

export default CustomTextInput
