import { FiShoppingBag } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { renderAmount } from '../../utils/helper'
import _ from 'lodash'
import { getCartMeta } from '../../API/Cart'

const MinifiedCart = () => {
  const location = useLocation()

  const cart = useSelector((state) => state.cart)
  const { total_cost, no_of_items } = getCartMeta({ cart })

  if (
    _.size(cart) === 0 ||
    location.pathname.match('/cart') ||
    location.pathname.match('/orders') ||
    location.pathname.match('/settings')
  ) {
    return null
  }

  return (
    <Link to={'/cart'} style={{ textDecoration: 'none' }}>
      <div
        className="hidden fixed bottom-2 lg:bottom-12 bg-primary text-onPrimary w-11/12 lg:w-screen-45 px-6 py-3
                        flex flex-row justify-between items-center rounded-md -translate-x-2/4 left-2/4 hover:bg-primaryDark"
      >
        <div className="flex items-center">
          <div
            className={`mr-2 pr-2 ${
              total_cost ? 'border-r border-r-onPrimary' : ''
            }`}
          >
            {no_of_items} item{no_of_items > 1 ? 's' : ''}
          </div>
          {total_cost ? (
            <div className="font-semibold">
              {total_cost < 2
                ? renderAmount(2, false)
                : renderAmount(total_cost, false)}
            </div>
          ) : null}
        </div>
        <div className="flex flex-row items-center text-sm">
          View Cart <FiShoppingBag className="text-onPrimary ml-2" />
        </div>
      </div>
    </Link>
  )
}
export default MinifiedCart
