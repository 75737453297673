import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPrintoutCostForNotesDocument } from '../../API/Cart'

const ModuleItem = ({ module, shop, index }) => {
  const shop_id = shop.id
  const onClickAdd = (key, module, cost) => {
    dispatch({
      type: 'ADD_CART',
      key: key,
      shop_id,
      data: module,
      cost: cost,
    })
  }

  const increaseQuantity = (key, cost) => {
    dispatch({ type: 'INCREASE_QUANTITY', shop_id, key: key, cost: cost })
  }
  const decreaseQuantity = (key, cost) => {
    dispatch({ type: 'DECREASE_QUANTITY', shop_id, key: key, cost: cost })
  }

  const cart = useSelector((state) => state.cart)
  const dispatch = useDispatch()
  const path = module['path']

  const modulePath = path
  const modulePathStr = modulePath.join(' • ')
  let in_cart = false
  let quantity = 0
  const item = cart.find((item) => item.key == modulePathStr)
  if (item) {
    in_cart = true
    quantity = item.quantity
  }
  const { no_of_pages } = module
  const printout_cost = getPrintoutCostForNotesDocument({ shop, no_of_pages })
  const cost = Math.ceil(no_of_pages * printout_cost)
  module['key'] = modulePathStr
  module['group_by'] = modulePath.slice(0, modulePath.length - 1).join(' • ')
  module['shop'] = shop
  module['shop_id'] = shop.id
  module['shop_name'] = shop.name
  module['cost'] = cost

  const name = module.name.replace(/\.[^.]+$/, '')

  return (
    <div
      className="border-b border-b-surface px-4 py-4 flex flex-row flex-1 justify-between items-center w-full inline-block"
      key={index}
    >
      <div className="flex flex-1 whitespace-normal w-screen-2 overflow-hidden">
        <div className="whitespace-normal overflow-hidden">
          <span style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
            {name}
          </span>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center flex-1">
        {/* <div className="font-semibold">{renderAmount(cost, false)}</div> */}
        <div
          className="w-24 px-2 py-1 rounded-md ml-6 mr-2 border border-onBackgroundDull text-success text-center hover:cursor-pointer"
          onClick={() => {
            if (!in_cart) {
              onClickAdd(modulePathStr, module, cost)
            }
          }}
        >
          {!in_cart ? (
            <button className="font-semibold">ADD</button>
          ) : (
            <div className="flex flex-row justify-between font-semibold">
              <button
                className="flex flex-1 justify-center"
                onClick={() => decreaseQuantity(modulePathStr, cost)}
              >
                -
              </button>
              <div className="flex flex-1 justify-center hover:cursor-default">
                {quantity}
              </div>
              <button
                className="flex flex-1 justify-center"
                onClick={() => increaseQuantity(modulePathStr, cost)}
              >
                +
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ModuleItem
