import React from 'react'
import { renderAmount } from '../../utils/helper'

const ExtraCost = ({ total_cost }) => {
  return (
    <div className="flex justify-center">
      <div className="mx-4 mt-6 mb-4 px-6 py-2 rounded-md bg-errorBackground text-error">
        Extra {renderAmount(2 - total_cost, false)} will be charged as minimum
        order should be {renderAmount(2, false)}
      </div>
    </div>
  )
}

export default ExtraCost
