import React from 'react'
import thumbsUpAnimationData from '../assets/animations/thumbs-up.json'
import Lottie from 'react-lottie-player'

const RatingSubmittedModal = ({ shop, onClickClose }) => {
  return (
    <div>
      <div
        data-aos="zoom-out"
        className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      >
        <div className="bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
          <div>
            <div className="-mt-4 flex justify-center items-center">
              <Lottie
                loop={false}
                animationData={thumbsUpAnimationData}
                play
                style={{ width: 100, height: 100 }}
                segments={[11, 36]}
              />
            </div>
            <div className="text-xl font-semibold text-success text-center mb-6 -mt-2">
              Rating Submitted
            </div>
            <div className="text-onBackground">
              We appreciate you taking the time to rate your experience. Your
              opinion helps us improve our service and better serve our
              customers like you.
            </div>
            <button
              className="w-full bg-primary text-onPrimary rounded-md py-4 mt-10 text-center hover:bg-primaryDark"
              onClick={onClickClose}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RatingSubmittedModal
