import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { formatCart, getCartMeta } from '../API/Cart'
import PaymentModal from './partials/PaymentModal'
import { renderGroupBy } from './partials/MyOrderItem'
import ReactGA from 'react-ga4'
import cartAnimationData from '../assets/animations/empty-cart.json'
import Lottie from 'react-lottie-player'
import { renderAmount } from '../utils/helper'
import ShopDetailsModal from './partials/ShopDetailsModal'
import RatingSubmittedModal from './RatingSubmittedModal'
import UserDetailsModal from './partials/UserDetailsModal'
import { submitUserDetails } from '../API/root'
import CustomDocumentMinified from './partials/CustomDocumentMinified'
import CustomDocumentCustomization from './CustomDocumentCustomization'
import ShopDetailsCart from './partials/ShopDetailsCart'
import UploadCustomDocuments from './partials/UploadCustomDocuments'
import UploadingFilesModal from './partials/UploadingFilesModal'
import DocumentPreviewModal from './partials/DocumentPreviewModal'
import ExtraCost from './partials/ExtraCost'

const Cart = () => {
  const navigate = useNavigate()

  const dispatch = useDispatch()
  // const shopNameDivRef = useRef(null)

  const navbar_height = useSelector((state) => state.navbar_height)
  const root_user = useSelector((state) => state.root_user)
  const bottom_navbar_height = useSelector(
    (state) => state.bottom_navbar_height
  )
  const cart = useSelector((state) => state.cart)
  const { total_cost, cart_type } = getCartMeta({ cart })
  let { formattedCart, finalOrders, shop } = formatCart(cart)

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)
  const [isUserDetailsModalVisible, setIsUserDetailsModalVisible] =
    useState(false)
  const [shopNameDivHeight, setShopNameDivHeight] = useState(0)
  const [showShopDetails, setShowShopDetails] = useState(0)
  const [showRatingSubmittedModal, setShowRatingSubmittedModal] =
    useState(false)
  const [custom_document, setCustomDocument] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [preview_document, setPreviewDocument] = useState(null)

  const togglePreview = (doc) => {
    setPreviewDocument(doc)
  }

  const toggleShowRatingSubmittedModal = () => {
    setShowRatingSubmittedModal(!showRatingSubmittedModal)
  }

  const toggleUserDetailsModal = () => {
    setIsUserDetailsModalVisible(!isUserDetailsModalVisible)
  }

  const onClickClearCart = () => {
    dispatch({ type: 'CLEAR_CART' })
  }

  const increaseQuantity = (key, shop_id, cost) => {
    dispatch({ type: 'INCREASE_QUANTITY', shop_id, key: key, cost: cost })
  }
  const decreaseQuantity = (key, shop_id, cost) => {
    dispatch({ type: 'DECREASE_QUANTITY', shop_id, key: key, cost: cost })
  }

  const handleEmailModal = () => {
    setIsEmailModalOpen(!isEmailModalOpen)
  }

  const onSubmitUserDetails = async ({ phone }) => {
    const data = {
      phone,
    }
    await submitUserDetails({ user_id: root_user['id'], data })
    dispatch({ type: 'ROOT_USER', data: { ...root_user, phone } })
    toggleUserDetailsModal()
  }

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Cart',
    })
  }, [])

  useEffect(() => {
    if (root_user && !root_user['phone']) {
      setIsUserDetailsModalVisible(true)
    }
  }, [root_user, isUserDetailsModalVisible])

  const toggleShopDetails = () => {
    setShowShopDetails(!showShopDetails)
  }

  if (_.size(formattedCart) == 0) {
    return (
      <div className="flex flex-1 flex-col justify-center items-center h-screen overflow-y-hidden">
        <div className="-mt-24 flex flex-col justify-center items-center uppercase tracking-widest text-onBackground">
          <Lottie
            loop
            animationData={cartAnimationData}
            play
            style={{ width: 200, height: 200 }}
          />
          <div className="mt-4">Oops! Nothing in cart</div>
        </div>
        <Link to={`/`} style={{ textDecoration: 'none' }} className="my-2 mt-4">
          <button className="mt-4 bg-primary text-onPrimary px-6 py-3 rounded-md hover:bg-primaryDark">
            Browse
          </button>
        </Link>
      </div>
    )
  }

  const formattedCart_len = _.size(formattedCart)

  return (
    <div
      className="mb-36 lg:mb-24"
      style={{ marginTop: `${navbar_height + 10}px` }}
    >
      <>
        <ShopDetailsCart shop={shop} toggleShopDetails={toggleShopDetails} />
        {cart_type == 'DOCUMENT' ? (
          <UploadCustomDocuments setUploading={setUploading} from_cart={true} />
        ) : null}
        {_.map(formattedCart, (groupbBy, index) => {
          const is_last = index == formattedCart_len - 1
          const groupbBy_len = _.size(groupbBy)
          return (
            <div
              key={index}
              className="w-full bg-background pt-1 sticky"
              style={{ top: `${navbar_height + 1}px` }}
            >
              {/* <div> */}
              <div className="bg-background mr-4">
                {renderGroupBy(groupbBy[0].group_by)}
              </div>
              {/* </div> */}
              {_.map(groupbBy, (item, inner_index) => {
                const name = item.name.replace(/\.[^.]+$/, '')
                const shop_id = item.shop_id
                const border =
                  inner_index == groupbBy_len - 1 && !is_last
                    ? ''
                    : 'border-b border-b-surface'
                if (item['type'] == 'DOCUMENT') {
                  return (
                    <CustomDocumentMinified
                      key={inner_index}
                      doc={item}
                      setCustomDocument={setCustomDocument}
                      shop={shop}
                      togglePreview={togglePreview}
                      // deleteItem={deleteItem}
                      // onClickCustomize={onClickCustomize}
                      // increaseQuantity={increaseQuantity}
                      // decreaseQuantity={decreaseQuantity}
                    />
                  )
                }

                return (
                  <div
                    className={`${border} px-4 py-4 flex flex-row gap-4 justify-between items-center
                                  w-full inline-block`}
                    key={inner_index}
                  >
                    <div className="flex flex-1 whitespace-normal w-screen-2 overflow-hidden">
                      <div className="break-words overflow-hidden">{name}</div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div className="font-semibold">
                        {/* {renderAmount(item.cost * item.quantity, false)} */}
                      </div>
                      <div className="font-semibold cursor-pointer w-24 px-2 py-1 rounded-md ml-6 mr-2 border border-onBackgroundDull text-success text-center">
                        <div className="flex flex-row justify-between">
                          <div
                            className="flex flex-1 justify-center"
                            onClick={() =>
                              decreaseQuantity(item.key, shop_id, item.cost)
                            }
                          >
                            -
                          </div>
                          <div className="flex flex-1 justify-center">
                            {item.quantity}
                          </div>
                          <div
                            className="flex flex-1 justify-center"
                            onClick={() =>
                              increaseQuantity(item.key, shop_id, item.cost)
                            }
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </>
      {total_cost > 0 && total_cost < 2 ? (
        <ExtraCost total_cost={total_cost} />
      ) : null}
      {total_cost > 0 ? (
        <div
          className="lg:hidden fixed bottom-0 lg:bottom-8 left-0 w-full lg:w-screen-30 flex flex-row justify-between items-center bg-primary py-4 px-4
          shadow-[#626370_0px_20px_20px_10px] shadow-xl rounded-t-lg lg:rounded-b-lg -translate-x-2/4 left-2/4"
        >
          <div className="text-4xl font-semibold text-onPrimary">
            {total_cost < 2
              ? renderAmount(2, false)
              : renderAmount(total_cost, false)}
          </div>
          <button
            className="bg-background text-onSurface px-4 py-2 rounded-md my-2
            hover:bg-surface"
            onClick={handleEmailModal}
          >
            Proceed to Pay
          </button>
        </div>
      ) : null}
      {total_cost > 0 ? (
        <div
          className="hidden lg:inline-flex fixed bottom-2 lg:bottom-12 bg-primary text-onPrimary w-11/12 lg:w-3/5  px-6 py-3
        flex flex-row justify-center items-center rounded-md  left-1/5 hover:cursor-pointer hover:bg-primaryDark"
          onClick={handleEmailModal}
        >
          <div className="font-semibold text-onPrimary flex flex-row justify-center items-center">
            <div className="text-onPrimary mr-2">Pay</div>
            <div>
              {total_cost < 2
                ? renderAmount(2, false)
                : renderAmount(total_cost, false)}
            </div>
          </div>
        </div>
      ) : null}
      {isEmailModalOpen ? (
        <PaymentModal
          onClose={handleEmailModal}
          finalOrders={finalOrders}
          total_cost={total_cost}
        />
      ) : null}
      {showShopDetails ? (
        <ShopDetailsModal
          onClose={toggleShopDetails}
          shop_temp={shop}
          toggleShowRatingSubmittedModal={toggleShowRatingSubmittedModal}
        />
      ) : null}
      {showRatingSubmittedModal ? (
        <RatingSubmittedModal onClickClose={toggleShowRatingSubmittedModal} />
      ) : null}
      {isUserDetailsModalVisible ? (
        <UserDetailsModal
          onClose={toggleUserDetailsModal}
          onConfirm={onSubmitUserDetails}
        />
      ) : null}
      {custom_document ? (
        <CustomDocumentCustomization
          doc={custom_document}
          setCustomDocument={setCustomDocument}
        />
      ) : null}
      {uploading ? <UploadingFilesModal /> : null}
      {preview_document ? (
        <DocumentPreviewModal onClose={togglePreview} doc={preview_document} />
      ) : null}
    </div>
  )
}

export default Cart
