import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { readDocumentsFromCollectionWithParentId } from '../API/root'
import Loading from './partials/Loading'
import ModuleItem from './partials/ModuleItem'
import { FiChevronRight } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import { FiFolder } from 'react-icons/fi'

const renderPath = (shop, data) => {
  let path_ids = data[0]['path_ids']
  path_ids = path_ids.slice(0, -1)
  const path_len = _.size(path_ids)
  if (path_len == 0) {
    return
  }
  return (
    <div className="max-w-almost bg-surface mb-4 mr-4 px-2 py-1 border border-onBackgroundDull border-l-0 inline-block rounded-r-full">
      {_.map(path_ids, (p, i) => (
        <div className="inline-flex flex flex-row items-center py-1" key={i}>
          <Link
            to={`/shops/${shop.id}/${p.id}`}
            key={p.id}
            state={{
              shop: shop,
              parent_id: p.id,
            }}
            style={{ textDecoration: 'none' }}
          >
            <span className="hover:bg-onBackgroundDull py-1 px-2 rounded-full">
              {p['key']}
            </span>
          </Link>
          {i != path_len - 1 ? <FiChevronRight /> : null}
        </div>
      ))}
    </div>
  )
}

const Listing = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const navbar_height = useSelector((state) => state.navbar_height)
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])

  let shop = null,
    parent_id = null

  if (location.state) {
    shop = location.state.shop
    parent_id = location.state.parent_id
  }

  const getData = async () => {
    setIsLoading(true)
    let temp_data = await readDocumentsFromCollectionWithParentId({
      collectionName: `Shops/${shop.id}/notes`,
      parentId: parent_id,
    })
    const groupedData = _.groupBy(temp_data, 'type')
    const sortedData = _.mapValues(groupedData, (group) =>
      _.sortBy(group, (item) => item.name.toLowerCase())
    )
    temp_data = _.concat(
      _.get(sortedData, 'FOLDER', []),
      _.get(sortedData, 'FILE', [])
    )
    // temp_data = _.sortBy(temp_data, (item) => item.name.toLowerCase())
    temp_data = _.map(temp_data, (item) => {
      const path = _.map(item['path_ids'], 'key')
      return { ...item, path }
    })
    setData(temp_data)
    setIsLoading(false)
  }

  useEffect(() => {
    if (shop) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: shop['name'],
      })
      getData()
    } else {
      navigate('/')
    }
  }, [parent_id])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="mb-20" style={{ marginTop: `${navbar_height + 10}px` }}>
      {/* <Heading
        title={shop['name']}
        Icon={FiUser}
        IconNavigateTo={'/my-orders'}
      /> */}
      {/* <div className="mr-4 mb-4 bg-surface text-onSurface pl-4 pr-6 py-2 rounded-r-full self-start inline-block"> */}
      {_.size(data) > 0 ? renderPath(shop, data) : null}
      {/* </div> */}
      {_.map(data, (entry, index) => {
        if (entry['type'] == 'FILE') {
          return (
            <ModuleItem module={entry} shop={shop} index={index} key={index} />
          )
        }
        return (
          <Link
            to={`/shops/${shop.id}/${entry.id}`}
            key={entry.id}
            state={{
              shop: shop,
              parent_id: entry.id,
              path_ids: entry.path_ids,
            }}
            style={{ textDecoration: 'none' }}
          >
            <div
              className="border-b border-b-surface px-4 py-4 flex flex-row items-center
                                  w-full inline-block cursor-pointer hover:bg-surface"
              key={index}
            >
              <FiFolder className="mr-2 w-8" />
              {entry.name}
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default Listing
