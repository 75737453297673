import { getCartMeta } from '../../API/Cart'

const initialState = {
  cart: [],
  noOfItems: 0,
  totalCost: 0,
  root_user: {},
  shop: {},
  stats: {},
  navbar_height: 0,
  bottom_navbar_height: 0,
  y: 0,
}

function RootReducer(state = initialState, action) {
  let item = null
  switch (action.type) {
    case 'ADD_CART':
      const shop_id = action.data.shop_id
      const { cart_shop_id, cart_type } = getCartMeta({ cart: state.cart })
      if (cart_shop_id && shop_id && cart_shop_id != shop_id) {
        alert('Only one shop allowed per order')
        return state
      }
      if (cart_type && cart_type != action.data.type) {
        alert(
          'You can either upload your documents or get printout of existing documents in a single order. Please clear cart.'
        )
        return state
      }
      return {
        ...state,
        cart: [...state.cart, { ...action.data, quantity: 1 }],
      }
    case 'INCREASE_QUANTITY':
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.key == action.key
            ? { ...item, quantity: item.quantity + 1 }
            : item
        ),
      }
    case 'DECREASE_QUANTITY':
      item = state.cart.find((item) => item.key == action.key)
      if (item.quantity == 1) {
        return {
          ...state,
          cart: state.cart.filter((item) => item.key !== action.key),
        }
      }
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.key === action.key
            ? { ...item, quantity: item.quantity - 1 }
            : item
        ),
      }
    case 'UPDATE_CART_ITEM':
      item = state.cart.find((item) => item.key == action.key)
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.key === action.key ? { ...item, ...action.data } : item
        ),
      }
    case 'CLEAR_CART':
      return {
        ...state,
        cart: [],
        noOfItems: 0,
        totalCost: 0,
      }
    case 'ROOT_USER':
      return {
        ...state,
        root_user: action.data,
      }
    case 'SHOP':
      return {
        ...state,
        shop: action.data,
      }
    case 'STATS':
      return {
        ...state,
        stats: action.data,
      }
    case 'NAVBAR_HEIGHT':
      return {
        ...state,
        navbar_height: action.data,
      }
    case 'BOTTOM_NAVBAR_HEIGHT':
      return {
        ...state,
        bottom_navbar_height: action.data,
      }
    case 'Y':
      return {
        ...state,
        y: action.data,
      }

    case 'LOGOUT':
      return {
        ...state,
        root_user: {},
        cart: [],
        shop: {},
        stats: {},
        noOfItems: 0,
        totalCost: 0,
      }
    default:
      return state
  }
}

export default RootReducer
