import React from 'react'

const LogoutModal = ({ onClose, onConfirm }) => {
  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        <div>
          <div className="text-xl text-onSurface font-semibold mb-6">
            Confirm
          </div>
          <p className="text-onBackground">Are you sure you want to log out?</p>
          <div className="mt-8 mb-2">
            <button
              className="border border-onBackground text-onBackground px-8 py-3 mr-4 rounded-md
              hover:bg-surface"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="border border-onBackground text-onSurface bg-onBackgroundDull px-8 py-3 rounded-md
              hover:bg-onBackground hover:text-background"
              onClick={onConfirm}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoutModal
