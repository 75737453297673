import React from 'react'
import { FiChevronRight, FiUser } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { CiViewList } from 'react-icons/ci'

import { Link } from 'react-router-dom'

export const Profile = () => {
  const navbar_height = useSelector((state) => state.navbar_height)

  return (
    <div style={{ marginTop: `${navbar_height + 2}px` }}>
      <div className="py-2">
        <Link to={'/my-orders'}>
          <div className="flex space-between items-center px-4 py-4 border-b border-b-onBackgrounDull w-full hover:bg-surface">
            <div className="flex flex-1 items-center">
              <CiViewList className="mr-3" />
              My Orders
            </div>
            <FiChevronRight className="text-onBackground" />
          </div>
        </Link>
        <Link to={'/account'}>
          <div className="flex space-between items-center px-4 py-4 border-b border-b-onBackgrounDull w-full hover:bg-surface">
            <div className="flex flex-1 items-center ">
              <FiUser className="mr-3" />
              Account
            </div>
            <FiChevronRight className="text-onBackground" />
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Profile
