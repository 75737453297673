import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { FiChevronRight, FiX } from 'react-icons/fi'
import {
  getDateForOrder,
  isPlaceOpenNow,
  renderAmount,
  timingsMeta,
} from '../../utils/helper'
import { CiShop } from 'react-icons/ci'

const date = new Date()
const current_date = moment(date.getTime()).format('DD/MM/YY')

export const renderGroupBy = (group_by) => {
  const path = group_by.split(' • ')
  const path_len = _.size(path)
  if (path_len == 0 || _.isEqual(path, [''])) {
    return null
  }
  return (
    <div className="max-w-almost bg-surface text-onSurface mr-4 px-4 py-1 border border-onBackgroundDull inline-block rounded-r-full">
      {_.map(path, (p, i) => (
        <div className="inline-flex flex flex-row items-center py-1" key={i}>
          {p}
          {i != path_len - 1 ? <FiChevronRight className="mx-2" /> : null}
        </div>
      ))}
    </div>
  )
}

const rendermodule = (modules, status, onClickDocument) => {
  return _.map(modules, (module, moduleIndex) => {
    const moduleName = module['name']
    const quantityColorClass =
      module.quantity > 1 ? 'font-semibold text-secondary' : 'text-onSurface'
    return (
      <div className="mx-4 my-1 flex flex-row w-full" key={moduleIndex}>
        <div className="flex flex-row justify-start w-full">
          <div className="flex flex-row items-center w-1/2">
            •
            <div className="ml-2 py-1 flex flex-1 whitespace-normal w-screen-2 overflow-hidden">
              <div
                className="break-words overflow-hidden"
                onClick={() =>
                  onClickDocument({ ...module, order_status: status })
                }
              >
                {moduleName}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="ml-4">
              <FiX />
            </div>
            <div className={`ml-4 py-1 ${quantityColorClass}`}>
              {module.quantity}
            </div>
          </div>
        </div>
      </div>
    )
  })
}

const MyDocumentItem = ({
  order,
  index,
  buttonText,
  setSelectedShop,
  onClickOrderStatus,
  onClickDocument,
}) => {
  const { shop } = order
  const { is_open_now, closes_soon } = isPlaceOpenNow(shop['open_hours'])
  const { timings_text, timings_style } = timingsMeta({
    is_open_now,
    closes_soon,
  })

  order['created_date_timestamp'] = order['created_date'].toDate().getTime()
  const order_date = getDateForOrder(order.created_date_timestamp)

  const razorpay_order_id = order['razorpay_order_id']?.replace('order_', '')
  const token = order['token']
  const otp = order['otp']
  const shop_name = order.shop?.name

  const { status } = order
  let status_color = null
  if (status == 'PENDING') {
    status_color = 'text-onBackground'
  } else if (status == 'READY') {
    status_color = 'text-success'
  } else {
    status_color = 'text-primary'
  }
  return (
    <div
      className="flex flex-row justify-center lg:justify-start hover:cursor-pointer"
      key={index}
    >
      {/* <div>
                      <span className="font-semibold mx-2">{index + 1}. </span>
                  </div> */}
      <div
        className="shadow-lg border border-surface py-4
                  flex flex-col-reverse
                  w-full md:w-3/4 lg:w-11/12 inline-block mb-6 rounded-lg mx-4"
        key={index}
      >
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-full">
            {rendermodule(order.modules, status, onClickDocument)}
            <div className="flex items-center justify-betwen mt-2">
              <div className="flex w-full px-2 ml-2 text-onBackground text-sm">
                {order_date ? (
                  <>
                    {current_date !== order_date ? (
                      <span>{order_date}</span>
                    ) : null}
                    {current_date !== order_date ? (
                      <span className="px-1">at</span>
                    ) : null}
                  </>
                ) : null}
                {moment(order.created_date_timestamp).format('h:mm a')}
              </div>
              <div className="mx-4 text-2xl font-semibold whitespace-nowrap">
                {renderAmount(order.amount)}
              </div>
            </div>
          </div>
        </div>
        {/* {order.status !== 'PENDING' ? ( */}
        <div
          className="inline-flex items-center mx-4 mb-4 text-md border-dashed
        border border-onSurface self-start rounded-md px-3 py-2"
        >
          <div className="token font-semibold text-primary mt-1 self-start pr-3">
            {token}
          </div>
          <div className="border-l border-l-onBackground pl-3 otp font-semibold text-secondary mt-1 self-start">
            {otp}
          </div>
        </div>
        <div className="mx-4 mb-2 flex flex-row justify-between items-center">
          <div className="mr-4 font-semibold order-id">{razorpay_order_id}</div>
          <div
            className={`status hover:cursor-pointer tracking-wide font-semibold ${status_color}`}
            onClick={() => onClickOrderStatus(order)}
          >
            {order.status}
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div
            className="shop-name inline-flex items-center font-semibold bg-primaryDull text-onSurface mr-4
        pl-3 pr-4 py-1 self-start rounded-r-full hover:cursor-pointer max-w-screen-2 hover:bg-primaryDull2"
            onClick={() => setSelectedShop(order.shop)}
          >
            <CiShop className="mr-2 text-lg" />
            <div className="line-clamp-1">{shop_name}</div>
          </div>
          {order.status == 'READY' ? (
            <div
              className={`px-3 py-1 rounded-full mr-4 text-sm hover:cursor-pointer ${timings_style}`}
              onClick={() => setSelectedShop(order.shop)}
            >
              {timings_text}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default MyDocumentItem
