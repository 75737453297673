import _ from 'lodash'
import React, { useState } from 'react'
import { createNotes } from '../API/root'
import { UserAuth } from './AuthContext'
import Loading from './partials/Loading'
import SettingsForm from './SettingsForm'

const activeTabStyle = 'bg-primary text-onPrimary'
const inActiveTabStyle = 'bg-surface text-onBackground'

function Settings() {
  const { token } = UserAuth()

  const [activeTab, setActiveTab] = useState('NOTES')
  const [files, setFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showNotesUploadConfirmModal, setShowNotesUploadConfirmModal] =
    useState(false)

  const extractPdfMetadata = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()

      fileReader.onload = function () {
        const typedArray = new Uint8Array(this.result)
        window.pdfjsLib
          .getDocument({ data: typedArray })
          .promise.then((pdfDocument) => {
            const no_of_pages = pdfDocument.numPages
            if (no_of_pages == 0) {
              reject('issue')
            }

            resolve({ path: file.webkitRelativePath, no_of_pages })
          })
          .catch((error) => {
            reject(error)
          })
      }

      fileReader.readAsArrayBuffer(file)
    })
  }

  const handleFolderChange = async (event) => {
    let files = event.target.files
    files = _.filter(files, (file) => file['type'] == 'application/pdf')

    try {
      const metadataPromises = Array.from(files).map((file) => {
        return extractPdfMetadata(file)
      })
      const metadataArray = await Promise.all(metadataPromises)
      setFiles(metadataArray)
    } catch (error) {
      console.error('Error extracting PDF files:', error)
    }
  }

  const uploadNotes = async () => {
    toggleNotesUploadConfirmModal()
    setIsLoading(true)
    try {
      await createNotes({ token, files })
      setIsLoading(false)
      setFiles([])
      alert('Notes Updated!')
    } catch (error) {
      setIsLoading(false)
      setFiles([])
    }
  }

  const toggleNotesUploadConfirmModal = () => {
    setShowNotesUploadConfirmModal(!showNotesUploadConfirmModal)
  }

  const setTab = (tab) => {
    setActiveTab(tab)
  }

  // useEffect(() => {
  //   if (_.size(files) > 0) {
  //     toggleNotesUploadConfirmModal()
  //   }
  // }, [files])

  if (isLoading) {
    return <Loading />
  }

  const notesTabStyle =
    activeTab === 'NOTES' ? activeTabStyle : inActiveTabStyle
  const profileTabStyle =
    activeTab === 'PROFILE' ? activeTabStyle : inActiveTabStyle

  const no_of_files = _.size(files)

  const allowed_upload = no_of_files > 0 ? true : false

  return (
    <div className="my-20 flex flex-col justify-center">
      {/* <div className="flex inline-flex justify-start mx-10">
        <div className="bg-surface rounded-full p-1">
          <div
            className={`mr-2 cursor-pointer inline-flex justify-center items-center py-2 w-32 rounded-full text-center ${notesTabStyle}`}
            onClick={() => setTab('NOTES')}
          >
            <div>Notes</div>
          </div>
          <div
            className={`cursor-pointer inline-flex justify-center items-center  py-2 w-32 rounded-full text-center ${profileTabStyle}`}
            onClick={() => setTab('PROFILE')}
          >
            <div>Profile</div>
          </div>
        </div>
      </div> */}
      {/* {activeTab == 'PROFILE' ? ( */}
      <SettingsForm />
      {/* ) : ( */}
      {/* <div className="mx-10 mt-10 flex flex-col flex-1 w-full md:w-2/6"> */}
      {/* <label htmlFor="fileInput" className="fileInputLabel inlin-block">
        <div
          className="h-48 w-96 flex flex-col justify-center items-center gap-4 p-10
          border border-onBackground border-dashed inline-block rounded-lg hover:cursor-pointer"
        >
          <div className="italic text-onBackground">
            Click to select the folder
          </div>
          {no_of_files > 0 ? (
            <div>
              {no_of_files} file
              {no_of_files != 1 ? 's' : ''}
            </div>
          ) : null}
        </div>
      </label>
      <input
        type="file"
        id="fileInput"
        webkitdirectory=""
        directory=""
        onChange={handleFolderChange}
        multiple
        style={{ display: 'none' }}
      />
      <div className="my-8">
        <button
          onClick={toggleNotesUploadConfirmModal}
          className={`px-6 py-3 rounded-md ${
            allowed_upload
              ? 'bg-primary text-onPrimary hover:bg-primaryDark'
              : 'bg-surface text-onSurface'
          }`}
          disabled={!allowed_upload}
        >
          Upload Notes
        </button>
      </div>
      {showNotesUploadConfirmModal ? (
        <NotesUploadConfirmModal
          onClose={toggleNotesUploadConfirmModal}
          onConfirm={uploadNotes}
        />
      ) : null} */}
      {/* </div> */}
      {/* )} */}
    </div>
  )
}

export default Settings
