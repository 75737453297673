import { serverTimestamp, Timestamp } from 'firebase/firestore'
import moment from 'moment'
import { FiChevronLeft, FiChevronRight, FiX } from 'react-icons/fi'
import { Slide, toast } from 'react-toastify'

export const trimString = (string) => {
  let stringTrim = string ? string.trim() : null
  stringTrim = stringTrim ? stringTrim : null
  return stringTrim
}

export const isEmpty = (string) => {
  if (string && string.trim() !== '') {
    return false
  }
  return true
}

export const isValidHttpUrl = (string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const getTimestamp = () => {
  let server_timestamp = serverTimestamp()
  let local_timestamp = Timestamp.now()
  return {
    server_timestamp,
    local_timestamp,
  }
}

export const generateRandomId = (length) => {
  return Math.floor(
    Math.pow(10, length - 1) +
      Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
  ).toString()
}

export const renderAmount = (amount, divide_by_hundred = true) => {
  const formatter = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
  })
  return divide_by_hundred
    ? formatter.format(amount / 100)
    : formatter.format(amount)
}

export const showToast = ({ message, background_color, color, autoClose }) => {
  toast(message, {
    transition: Slide,
    position: 'bottom-right',
    autoClose: autoClose ? autoClose : 1500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'light',
    style: {
      backgroundColor: background_color,
      color,
    },
  })
}

export const getDateForOrder = (date) => {
  const today = moment().startOf('day')
  const yesterday = moment().subtract(1, 'days').startOf('day')

  const momentDate = moment(date)

  if (momentDate.isSame(today, 'd')) {
    return null
  } else if (momentDate.isSame(yesterday, 'd')) {
    return 'Yesterday'
  } else {
    return momentDate.format('DD/MM/YY')
  }
}

export const isPlaceOpenNow = (open_hours) => {
  const now = moment()
  const currentDay = now.format('dddd') // Get the current day name
  const currentTime = now.format('HH:mm') // Get the current time

  const todaySchedule = open_hours.find(
    (daySchedule) => daySchedule.day === currentDay
  )

  if (
    !todaySchedule ||
    !todaySchedule.open ||
    todaySchedule.open.length === 0
  ) {
    return { is_open_now: false, closes_soon: false } // The business is closed on the current day
  }

  const openingIntervals = todaySchedule.open.map((interval) => {
    const startTime = moment(interval.start, 'HH:mm')
    const endTime = moment(interval.end, 'HH:mm')

    // If the end time is earlier than the start time, assume it spans across midnight
    if (endTime.isBefore(startTime)) {
      return [
        [moment('00:00', 'HH:mm'), endTime], // Interval from midnight to end time
        [startTime, moment('23:59', 'HH:mm')], // Interval from start time to midnight
      ]
    } else {
      return [[startTime, endTime]]
    }
  })

  // Flatten the array of intervals
  const flattenedIntervals = [].concat(...openingIntervals)

  // Check if the current time is within any of the specified open intervals for the day
  const isOpenNow = flattenedIntervals.some((interval) =>
    now.isBetween(interval[0], interval[1], null, '[]')
  )

  // Check if the business closes within the next 60 minutes
  const closesSoon = flattenedIntervals.some((interval) => {
    const endTime = interval[1]
    const timeUntilClose = endTime.diff(now, 'minutes')

    return timeUntilClose > 0 && timeUntilClose <= 60
  })

  return { is_open_now: isOpenNow, closes_soon: closesSoon }
}

export const timingsMeta = ({ is_open_now, closes_soon }) => {
  let timings_text = null,
    timings_style = null
  if (closes_soon) {
    timings_text = 'Closes soon'
    timings_style =
      'bg-warningBackground text-warning hover:bg-warningBackgroundDark'
  } else if (is_open_now) {
    timings_text = 'Open now'
    timings_style =
      'bg-successBackground text-success hover:bg-successBackgroundDark'
  } else {
    timings_text = 'Closed'
    timings_style = 'bg-errorBackground text-error hover:bg-errorBackgroundDark'
  }
  return { timings_text, timings_style }
}

const lightenHexColor = (hex, alpha) => {
  // Convert hex to RGB
  const rgb = hexToRgb(hex)

  // Calculate the lighter color
  const newRgb = {
    r: Math.round(rgb.r + (255 - rgb.r) * alpha),
    g: Math.round(rgb.g + (255 - rgb.g) * alpha),
    b: Math.round(rgb.b + (255 - rgb.b) * alpha),
  }

  // Convert RGB back to hex
  const newHex = rgbToHex(newRgb.r, newRgb.g, newRgb.b)

  return newHex
}

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16)
  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return { r, g, b }
}

const rgbToHex = (r, g, b) => {
  return `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`
}
// lightenHexColor('#546de5', 0.8)

export const tourGuidPrevButton = ({ currentStep, setCurrentStep, steps }) => {
  const first = currentStep === 0
  if (first) {
    return null
  }
  return (
    <span
      className="w-6 h-6 flex justify-center items-center bg-surface rounded-full mr-2 hover:cursor-pointer"
      onClick={() => {
        if (first) {
          setCurrentStep((s) => steps.length - 1)
        } else {
          setCurrentStep((s) => s - 1)
        }
      }}
    >
      <FiChevronLeft className="text-onSurface" />
    </span>
  )
}

export const tourGuidNextButton = ({
  Button,
  currentStep,
  stepsLength,
  setIsOpen,
  setCurrentStep,
  steps,
}) => {
  const last = currentStep === stepsLength - 1
  if (last) {
    return (
      <span
        className="px-4 py-1 text-sm bg-primary text-onPrimary rounded-full ml-2 hover:cursor-pointer"
        onClick={() => setIsOpen(false)}
      >
        Done
      </span>
    )
  }
  return (
    <span
      className="w-6 h-6 flex justify-center items-center bg-surface rounded-full ml-2 hover:cursor-pointer"
      onClick={() => {
        if (last) {
          setIsOpen(false)
        } else {
          setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
        }
      }}
    >
      <FiChevronRight className="text-onSurface" />
    </span>
  )
}

export const tourGuideCloseButton = ({ styles, onClick, disabled }) => {
  return (
    <span
      className="w-6 h-6 flex justify-center items-center rounded-full ml-2 hover:cursor-pointer"
      onClick={onClick}
      style={{ position: 'absolute', right: 10, top: 10 }}
    >
      <FiX className="text-onSurface" />
    </span>
  )
}

export const getCurrentDayIndex = () => {
  const currentDate = new Date()
  let dayIndex = currentDate.getDay()
  dayIndex = (dayIndex + 6) % 7
  return dayIndex
}

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}
