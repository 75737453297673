export const APP_NAME = 'QueueRelief'
export const DAYS = [
  {
    day: 'Monday',
    open: [],
  },
  {
    day: 'Tuesday',
    open: [],
  },
  {
    day: 'Wednesday',
    open: [],
  },
  {
    day: 'Thursday',
    open: [],
  },
  {
    day: 'Friday',
    open: [],
  },
  {
    day: 'Saturday',
    open: [],
  },
  {
    day: 'Sunday',
    open: [],
  },
]

export const tourguide_steps = [
  {
    selector: '.shop-name',
    content: 'Click to see shop details',
  },
  {
    selector: '.order-id',
    content: 'Order ID',
  },
  {
    selector: '.token',
    content: 'Order token',
  },
  {
    selector: '.otp',
    content: 'Order OTP',
  },
  {
    selector: '.status',
    content: 'Order status',
  },
]

export const tour_guide_styles = {
  popover: (base) => ({
    ...base,
    '--reactour-accent': '#546de5',
    borderRadius: 10,
    zIndex: 0,
  }),
  maskArea: (base) => ({
    ...base,
    rx: 4,
  }),
  maskWrapper: (base) => ({
    ...base,
    color: '#57697E',
    zIndex: 0,
  }),
  controls: (base) => ({
    ...base,
    marginTop: 24,
    marginHorizontal: 0,
  }),
  close: (base) => ({
    ...base,
    right: 10,
    left: 'auto',
    top: 10,
  }),
  arrow: (base) => ({
    ...base,
    zIndex: 22000,
  }),
}
