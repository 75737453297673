import React from 'react'
import animationData from '../../assets/animations/discount.json'
import Lottie from 'react-lottie-player'

const DiscountModal = ({ setShowDiscount }) => {
  const onClickClose = () => {
    setShowDiscount(false)
    localStorage.setItem('discount_shown', 'true')
  }

  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        <div>
          <div className="flex flex-col justify-center items-center">
            <Lottie
              loop={false}
              animationData={animationData}
              play
              style={{ width: 100, height: 100 }}
            />

            <div className="text-xl font-semibold text-primary text-center mb-6 mt-4">
              Discounts available
            </div>
            <div className="text-onBackground">
              Save time and money with Queuerelief
            </div>
            <div className="text-onSurface font-bold mt-1">
              Limited period offer!
            </div>
          </div>
          <div className="mt-4 text-onSurface"></div>
          <button
            className="w-full bg-primary text-onPrimary rounded-md py-4 mt-10 text-center hover:bg-primaryDark"
            onClick={onClickClose}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  )
}

export default DiscountModal
