import _ from 'lodash'

export const formatCart = (all_cart) => {
  let formattedCartTemp = [],
    formattedCart = [],
    finalOrders = [],
    required_sizes_types = [],
    shop = null,
    shop_name = null
  _.forEach(all_cart, (data) => {
    if (data['type'] == 'FILE') {
      finalOrders.push({
        id: data.id,
        path: data.path,
        shop_id: data.shop_id,
        quantity: data.quantity,
        type: data.type,
      })
    } else {
      required_sizes_types.push(`${data.page_size}_${data.print_type}`)
      let entry = {
        page_size: data.page_size,
        print_type: data.print_type,
        orientation: data.orientation,
        name: data.name,
        shop_id: data.shop_id,
        quantity: data.quantity,
        s3_presigned_url: data.url,
        s3_key: data.key,
        type: data.type,
      }
      if (data.print_style) {
        entry['print_style'] = data.print_style
      }
      finalOrders.push(entry)
    }

    shop = data['shop']
    shop_name = data['shop_name']
    formattedCartTemp.push(data)
  })
  formattedCartTemp = _.groupBy(formattedCartTemp, 'group_by')
  _.forOwn(formattedCartTemp, (data, key) => {
    formattedCart.push(data)
  })
  return { formattedCart, finalOrders, shop_name, shop, required_sizes_types }
}

export const getCostFromRange = ({ range, no_of_pages }) => {
  let cost = 0
  for (const entry of range) {
    if (no_of_pages >= entry['from'] && no_of_pages <= entry['to']) {
      cost = entry['cost']
    }
  }
  return cost
}

export const getPrintoutCostForNotesDocument = ({ shop, no_of_pages }) => {
  const { pricing } = shop
  let cost = 0
  const range = pricing['bw_printout_cost_notes']
  cost = getCostFromRange({ range, no_of_pages })
  return cost
}

const getPricingKey = (text) => {
  let key = null
  switch (text) {
    case 'A4_bw':
      key = 'A4_bw_printout_cost'
      break
    case 'A4_color':
      key = 'A4_color_printout_cost'
      break
    case 'A3_bw':
      key = 'A3_bw_printout_cost'
      break
    case 'A3_color':
      key = 'A3_color_printout_cost'
      break
    case 'notes':
      key = 'bw_printout_cost_notes'
      break
  }
  return key
}

export const getCartMeta = ({ cart }) => {
  let range = []
  let total_cost = 0,
    no_of_items = 0,
    cart_shop_id = null,
    cart_type = null,
    pricing = {},
    printout_cost = 0,
    printout_data = {}
  if (_.size(cart) > 0) {
    const shop = cart[0]['shop']
    cart_type = cart[0]['type']
    if (shop) {
      cart_shop_id = shop['id']
      pricing = shop['pricing']
    }
  }

  for (const item of cart) {
    no_of_items += item['quantity']
    const { no_of_pages } = item

    // if (cart_type == 'FILE') {
    //   range = pricing['bw_printout_cost_b2b_notes']
    // } else
    if (cart_type == 'DOCUMENT') {
      let no_of_sheets = no_of_pages

      if (
        item['page_size'] == 'A4' &&
        item['print_type'] == 'bw' &&
        item['print_style'] == 'b2b'
      ) {
        no_of_sheets = Math.ceil(no_of_pages / 2)
      }

      const mul = item['quantity'] * no_of_sheets

      let prefix = item['page_size'] ? item['page_size'] : 'A4'

      prefix += item['print_type'] == 'bw' ? '_bw' : '_color'

      if (_.has(printout_data, prefix)) {
        printout_data[prefix] += mul
      } else {
        printout_data[prefix] = mul
      }
    }
    if (cart_type == 'FILE') {
      const no_of_sheets = Math.ceil(no_of_pages / 2)
      const mul = item['quantity'] * no_of_sheets
      if (_.has(printout_data, 'notes')) {
        printout_data['notes'] += mul
      } else {
        printout_data['notes'] = mul
      }
    }
  }
  if (!_.isEmpty(pricing)) {
    _.forOwn(printout_data, (mul, text) => {
      const pricing_key = getPricingKey(text)
      const range = pricing[pricing_key]
      if (!_.isEmpty(range)) {
        const printout_cost = getCostFromRange({
          range,
          no_of_pages: mul,
        })
        total_cost += Math.ceil(printout_cost * mul)
      }
    })
  }
  return { total_cost, no_of_items, cart_shop_id, cart_type }
}
