import _ from 'lodash'
import React from 'react'
import { generateRandomId } from '../../utils/helper'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getS3PutSignedURLs, uploadFileToS3PutSignedURL } from '../../API/root'
import { UserAuth } from '../AuthContext'
import { FaPlusCircle } from 'react-icons/fa'

export const MAX_DOCUMENTS = 10

const UploadCustomDocuments = ({ setUploading, from_cart }) => {
  const { token } = UserAuth()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const cart = useSelector((state) => state.cart)
  const cart_size = _.size(cart)

  const extractPdfMetadata = async (file) => {
    if (file['size'] > 100000000) {
      alert(`${file['name']} size is too big`)
      setUploading(false)
      return
    }
    const key = generateRandomId(10)
    if (file['type'] == 'application/pdf') {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()

        fileReader.onload = function () {
          const typedArray = new Uint8Array(this.result)
          window.pdfjsLib
            .getDocument({ data: typedArray })
            .promise.then((pdfDocument) => {
              const no_of_pages = pdfDocument.numPages
              if (no_of_pages == 0) {
                reject('issue')
              }
              let entry = {
                id: key,
                key,
                group_by: '',
                file: file,
                name: file.name,
                no_of_pages,
                page_size: 'A4',
                orientation: 'portrait',
                quantity: 1,
                print_type: 'bw',
                print_style: 'single',
                type: 'DOCUMENT',
              }
              resolve(entry)
            })
            .catch((error) => {
              reject(error)
            })
        }

        fileReader.readAsArrayBuffer(file)
      })
    }
    return {
      id: key,
      key,
      group_by: '',
      file: file,
      name: file.name,
      no_of_pages: 1,
      page_size: 'A4',
      orientation: 'portrait',
      quantity: 1,
      print_type: 'bw',
      print_style: 'single',
      type: 'DOCUMENT',
    }
  }

  const uploadFiles = async (files) => {
    setUploading(true)
    const no_of_files = _.size(files)
    const file_names = _.map(files, 'name')
    const url_keys = await getS3PutSignedURLs({
      token,
      file_names,
    })
    if (_.size(url_keys) != _.size(file_names)) {
      alert('Issue with uploaded file(s)')
      setUploading(false)
      return
    }
    const filesWithUrls = _.map(files, (file, index) =>
      _.merge({}, file, url_keys[index])
    )

    try {
      const uploadPromises = filesWithUrls.map((entry, index) =>
        uploadFileToS3PutSignedURL({
          file: entry['file'],
          url: entry['url'],
        })
      )
      await Promise.all(uploadPromises)

      let shop = null

      if (_.size(cart) > 0) {
        shop = cart[0]['shop']
      }

      for (let item of filesWithUrls) {
        if (shop) {
          item['shop'] = shop
          item['shop_id'] = shop['id']
        }
        dispatch({
          type: 'ADD_CART',
          key: item.key,
          data: item,
          cost: 0,
        })
      }
      setUploading(false)
      navigate('/cart')
    } catch (error) {
      alert('error 2', error)
      console.log(error)
      setUploading(false)
    }
  }

  const handleFileSelect = async (event) => {
    let files_temp = event.target.files
    files_temp = _.filter(files_temp, (file) => {
      const type = file['type']
      if (
        type == 'application/pdf' ||
        type == 'image/png' ||
        type == 'image/jpg' ||
        type == 'image/jpeg'
      ) {
        return true
      }
    })

    if (_.size(files_temp) + cart_size > MAX_DOCUMENTS) {
      alert(`Maximum of ${MAX_DOCUMENTS} documents per order`)
      return
    }

    try {
      const metadataPromises = Array.from(files_temp).map((file) => {
        return extractPdfMetadata(file)
      })
      const metadataArray = await Promise.all(metadataPromises)
      uploadFiles(metadataArray)
      // setFiles([...files, ...metadataArray])
    } catch (error) {
      alert(error)
      alert('Issue with uploaded file(s)')
    }
  }

  return (
    <>
      {!from_cart ? (
        <label htmlFor="fileInputEmpty" className="fileInputLabel">
          <div className="lg:flex lg:h-screen-11/12 lg:items-center lg:justify-center">
            <div className="hidden lg:block lg:w-1/2 rounded-xl px-10 py-8 shadow-md border border-surface">
              <div className="flex items-center">
                <img src="add-files.png" className="w-12" />
                <div className="ml-4">
                  <div className="font-bold text-2xl">Upload your files</div>
                  <div className="mt-1 text-onBackground text-sm">
                    We support PDF, JPG and PNG
                  </div>
                </div>
              </div>

              <div className="mt-10 text-sm text-onBackground">
                Maximum upload file size: 100 MB
              </div>
              <div className="text-sm text-onBackground">Maximum files: 10</div>
              <label
                htmlFor="fileInputEmpty"
                className="fileInputLabel inline-block"
              >
                <div className="mt-8 bg-primary text-onPrimary px-6 py-3 rounded-md hover:bg-primaryDark hover:cursor-pointer">
                  Upload
                </div>
              </label>
              <input
                type="file"
                id="fileInputEmpty"
                onChange={handleFileSelect}
                multiple
                style={{ display: 'none' }}
                accept=".pdf,.jpg,.jpeg,.png"
              />
            </div>
            <div
              className="lg:hidden flex space-x-4 mx-4 border border-onBackgroundDull rounded-md p-4
                hover:bg-primaryDull hover:cursor-pointer lg:w-1/2"
            >
              {/* <div className="w-6 h-6 border border-onBackground rounded-full"></div> */}
              <div className="-mt-1">
                <div className="font-semibold text-lg">Upload documents</div>
                <div className="">We support PDF, JPG and PNG</div>
                <div className="mt-2">
                  <div className="text-sm text-onBackground">
                    Maximum upload file size: 100 MB
                  </div>
                  <div className="text-sm text-onBackground">
                    Maximum files: 10
                  </div>
                </div>
              </div>
            </div>
          </div>
        </label>
      ) : (
        <label htmlFor="fileInputEmpty" className="fileInputLabel">
          <div
            className="mx-10 mt-6 mb-2 lg:mb-10 flex flex-col justify-center items-center px-12 py-6
          border border-onBackground border-dashed rounded-lg lg:hover:cursor-pointer lg:hover:bg-successBackground"
          >
            <div className="flex items-center text-onSurface">
              <FaPlusCircle className="text-success text-lg" />
              <span className="pl-2">Click to add files</span>
            </div>
          </div>
        </label>
      )}

      <input
        type="file"
        id="fileInputEmpty"
        onChange={handleFileSelect}
        multiple
        style={{ display: 'none' }}
        accept=".pdf,.jpg,.jpeg,.png"
      />
    </>
  )
}

export default UploadCustomDocuments
