import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'
import paymentAnimationData from '../assets/animations/payment-success.json'
import Lottie from 'react-lottie-player'

const OrderCreatedModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const seachQuery = useSearchParams()[0]

  let order_id = seachQuery.get('order_id')
  order_id = order_id ? order_id.replace('order_', '') : null
  const token = seachQuery.get('token')
  const otp = seachQuery.get('otp')

  useEffect(() => {
    dispatch({ type: 'CLEAR_CART' })
  }, [])

  const onClickClose = () => {
    navigate('/')
  }

  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        <div>
          <div className="-mt-4 flex justify-center items-center">
            <Lottie
              loop={false}
              animationData={paymentAnimationData}
              play
              style={{ width: 100, height: 100 }}
            />
          </div>
          <div className="text-xl font-semibold text-success text-center mb-6 -mt-2">
            Order Created
          </div>
          <div className="text-onBackground">
            You will receive a whatapp message and an email once the order is
            ready. The order details can be viewed in your Profile.
          </div>

          <div className="mt-4 text-onSurface">
            Order ID: <span className="font-semibold">{order_id}</span>
          </div>
          {token ? (
            <div className="mt-2 text-onSurface">
              Token: <span className="font-semibold text-primary">{token}</span>
            </div>
          ) : null}
          {otp ? (
            <div className="mt-2 text-onSurface">
              OTP: <span className="font-semibold text-secondary">{otp}</span>
            </div>
          ) : null}
          <button
            className="w-full bg-primary text-onPrimary rounded-md py-4 mt-10 text-center hover:bg-primaryDark"
            onClick={onClickClose}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  )
}

export default OrderCreatedModal
