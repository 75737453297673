import React from 'react'
import { renderAmount } from '../../utils/helper'

const TopStats = ({ stats }) => {
  // const stats = useSelector((state) => state.stats)

  return (
    <div className="flex flex-col md:flex-row gap-4 justify-between mb-16 py-10 bg-surface rounded-xl">
      <div className="py-6 md:py-0 flex flex-1 flex-col px-10 border-r border-r-onBackgroundDull">
        <div className="font-semibold text-4xl text-center">
          {renderAmount(stats['total_amount_for_today'])}
        </div>
        <div className="mt-4 uppercase tracking-widest text-onBackground text-center">
          Today
        </div>
      </div>
      <div className="py-6 md:py-0 flex flex-1 flex-col px-10 border-r border-r-onBackgroundDull">
        <div className="font-semibold text-4xl text-center">
          {renderAmount(stats['total_amount_for_current_month'])}
        </div>
        <div className="mt-4 uppercase tracking-widest text-onBackground text-center">
          This Month
        </div>
      </div>
      <div className="py-6 md:py-0 lex flex-1 flex-col px-10">
        <div className="font-semibold text-4xl text-center">
          {renderAmount(stats['total_amount_for_current_year'])}
        </div>
        <div className="mt-4 uppercase tracking-widest text-onBackground text-center">
          This year
        </div>
      </div>
    </div>
  )
}

export default TopStats
