import { NavLink, useLocation } from 'react-router-dom'
import { UserAuth } from '../AuthContext'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTour } from '@reactour/tour'
import { useSelector } from 'react-redux'
import { getCartMeta } from '../../API/Cart'
import {
  FiChevronDown,
  FiChevronUp,
  FiHome,
  FiShoppingCart,
  FiUser,
} from 'react-icons/fi'

const common_style = 'py-3 px-4 cursor-pointer mx-4 rounded flex items-center '
const active_style = `${common_style} bg-[#33435a] text-background`
const inactive_style = `${common_style} text-surface hover:bg-[#242f3f]`

const UserSideNavBar = () => {
  const { isOpen } = useTour()
  const location = useLocation()
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
  const root_user = useSelector((state) => state.root_user)
  const { no_of_items } = getCartMeta({ cart })

  const [active_menu, setActiveMenu] = useState(null)

  const navRef = useRef(null)

  const handleMenuClick = (menu) => {
    if (active_menu === menu) {
      setActiveMenu(null)
    } else {
      setActiveMenu(menu)
    }
  }

  useEffect(() => {
    if (navRef.current) {
      const divWidth = navRef.current.clientWidth
      dispatch({ type: 'NAVBAR_WIDTH', data: divWidth })
    }
  }, [])

  // if (
  //   !(
  //     // location.pathname.match('/') ||
  //     (
  //       location.pathname.match('/orders') ||
  //       location.pathname.match('/analytics') ||
  //       location.pathname.match('/settings')
  //     )
  //   )
  // ) {
  //   return null
  // }

  const { logOut } = UserAuth()

  return (
    <div>
      <nav
        className={`h-screen pb-10 flex flex-col text-onSurface border-b border-b-surface justify-center fixed top-0 w-1/5
          bg-gray-800 border-r-2 border-r-onBackgroundDull ${
            !isOpen ? 'z-20' : ''
          }`}
        ref={navRef}
      >
        <div className="h-full flex flex-col space-between items-center">
          <div className="flex flex-col text-onSurface w-full">
            <NavLink
              to={`/home`}
              style={{ textDecoration: 'none' }}
              className="self-center"
            >
              <img
                src="logo-primary.png"
                className="h-10 self-center px-2 my-6"
              />
            </NavLink>
            <div className="border-b border-b-[#33435a] mb-6"></div>
            <NavLink
              to={`/home`}
              style={{ textDecoration: 'none' }}
              className={({ isActive }) =>
                isActive ? active_style : inactive_style
              }
            >
              <FiHome className="mr-4" />
              Home
            </NavLink>

            <NavLink
              to={`/cart`}
              style={{ textDecoration: 'none' }}
              className={({ isActive }) =>
                isActive ? active_style : inactive_style
              }
            >
              <FiShoppingCart className="mr-4" />
              Cart
              {no_of_items > 0 ? (
                <div className="ml-3 w-8 h-8 rounded-full flex justify-center items-center bg-primary text-onPrimary text-sm">
                  {no_of_items}
                </div>
              ) : null}
            </NavLink>
            <div
              className={`${inactive_style} flex justify-between items-center`}
              onClick={() => handleMenuClick('profile')}
            >
              <div className="flex items-center">
                <FiUser className="mr-4" />
                <div>Profile</div>
              </div>
              {active_menu == 'profile' ? (
                <FiChevronUp className="text-xl" />
              ) : (
                <FiChevronDown className="text-xl" />
              )}
            </div>
            {active_menu == 'profile' ? (
              <div className="">
                <NavLink
                  to={`/my-orders`}
                  style={{ textDecoration: 'none' }}
                  className={({ isActive }) =>
                    isActive
                      ? `${active_style} pl-16`
                      : `${inactive_style} pl-16`
                  }
                >
                  My Orders
                </NavLink>
                <NavLink
                  to={`/account`}
                  style={{ textDecoration: 'none' }}
                  className={({ isActive }) =>
                    isActive
                      ? `${active_style} pl-16`
                      : `${inactive_style} pl-16`
                  }
                >
                  Account
                </NavLink>
              </div>
            ) : null}
          </div>
        </div>
        {/* <NavLink
          to={`/account`}
          style={{ textDecoration: 'none' }}
          className={({ isActive }) =>
            isActive ? active_style : inactive_style
          }
        >
          <div className="mr-4 w-10 h-10 rounded-full flex justify-center items-center bg-secondary text-onPrimary uppercase tracking-widest">
            {root_user['abbrevation']}
          </div>
          Account
        </NavLink> */}
      </nav>
    </div>
  )
}

export default UserSideNavBar
