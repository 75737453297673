import React, { useEffect, useRef, useState } from 'react'
import Footer from './partials/Footer'
import { UserAuth } from './AuthContext'
import SmallLoading from './partials/SmallLoading'
import './Explode.css'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useSelector } from 'react-redux'
import { useWindowScroll } from 'react-use'
import { IoArrowForward, IoArrowBack } from 'react-icons/io5'
import { useInView } from 'react-intersection-observer'
import ConfettiExplosion from 'react-confetti-explosion'
import { FloatingIcons } from './partials/FloatingIcons'

gsap.registerPlugin(ScrollTrigger)

const SCROLL_THRESHOLD = 150
const COLORS = ['#fae5d3', '#dde2fa', '#cef4de']

const Landing = () => {
  const { googleSignIn, signInLoading } = UserAuth()

  const [nothing, setNothing] = useState(false)
  const [explode, setExplode] = useState(false)
  const stepsDivRef = useRef(null)
  const step1DivRef = useRef(null)
  const step2DivRef = useRef(null)
  const step3DivRef = useRef(null)
  const yl = useSelector((state) => state.y)
  const { y: scrollY } = useWindowScroll()

  // const [isScrolled, setIsScrolled] = useState(false)
  const [showSeeMore, setShowSeeMore] = useState(true)
  const [bgColor, setBgColor] = useState('#ffffff') // Initial background color

  const onClickHowWeWork = () => {
    stepsDivRef.current.scrollIntoView({
      behavior: 'smooth',
    })
    setShowSeeMore(false)
  }

  const moveToStep = (step) => {
    step.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const { ref, inView } = useInView({
    threshold: 0.1,
  })

  React.useEffect(() => {
    if (inView) {
      lastStepCompleteTriggerFunction()
    }
  }, [inView])

  const lastStepCompleteTriggerFunction = () => {
    setExplode(true)
  }
  const mounted = useRef(null)

  const onClickLogin = () => {
    if (nothing) {
      googleSignIn()
      return
    }
    googleSignIn()
    setTimeout(() => {
      setNothing(true)
    }, 1000)
  }

  useEffect(() => {
    gsap.set('.photo:not(:first-child)', {
      opacity: 1,
      scale: 1,
      clipPath: 'inset(100% 0% 0%)',
      scale: 1,
    })

    gsap.set('.photo img', {
      y: 5,
    })

    const animation = gsap.to('.photo:not(:first-child)', {
      opacity: 1,
      scale: 1,
      duration: 1,
      stagger: 1,
      clipPath: 'inset(0% 0% 0%)',
      scale: 1,
      snap: true,
    })

    gsap.to('.photo img', {
      y: -5,
      duration: 1,
      repeat: -1,
      yoyo: true,
      ease: 'power1.inOut',
    })

    ScrollTrigger.create({
      trigger: '.gallery',
      start: 'top top',
      end: 'bottom bottom',
      pin: '.right',
      animation: animation,
      scrub: 2,
    })

    ScrollTrigger.create({
      trigger: '.spacer',
      start: 'top center',
      end: 'bottom center',
      onEnter: () =>
        gsap.to('.gallery', { backgroundColor: '#ffffff', duration: 1 }),
      onEnterBack: () =>
        gsap.to('.gallery', { backgroundColor: '#ffffff', duration: 1 }),
    })

    gsap.utils.toArray('.details').forEach((detail, i) => {
      const bgColor = COLORS[i]
      ScrollTrigger.create({
        trigger: detail,
        start: 'top center',
        end: 'bottom center',
        onEnter: () =>
          gsap.to('.gallery', { backgroundColor: bgColor, duration: 1 }),
        onEnterBack: () =>
          gsap.to('.gallery', { backgroundColor: bgColor, duration: 1 }),
      })
    })

    // Animation for changing background color when entering the gallery
    ScrollTrigger.create({
      trigger: '.gallery',
      start: 'top center',
      onEnter: () => {
        gsap.to('.landing-container', {
          backgroundColor: COLORS[0],
          duration: 1,
        })
      },
      onEnterBack: () => {
        gsap.to('.landing-container', {
          backgroundColor: COLORS[0],
          duration: 1,
        })
      },
      onLeave: () => {
        gsap.to('.landing-container', {
          backgroundColor: '#ffffff',
          duration: 1,
        })
      },
      onLeaveBack: () => {
        gsap.to('.landing-container', {
          backgroundColor: '#ffffff',
          duration: 1,
        })
      },
    })
  }, [])

  useEffect(() => {
    if (window.innerWidth < 768) {
      setTimeout(() => {
        setShowSeeMore(false)
      }, 5000)
    }
  }, [])

  useEffect(() => {
    if (window.innerWidth >= 768) {
      if (scrollY > SCROLL_THRESHOLD || yl > SCROLL_THRESHOLD) {
        setShowSeeMore(false)
      } else {
        setShowSeeMore(true)
      }
    }
  }, [scrollY, yl])

  return (
    <div
      className="landing-container flex flex-col"
      style={{ backgroundColor: bgColor }}
    >
      <a href="/">
        <img
          src="logo-primary.png"
          className="h-10 md:h-12 lg:h-14 self-center absolute top-8 left-8 lg:top-10 lg:left-10
    hover:cursor-pointer"
          alt="QueueRelief Logo"
        />
      </a>
      <div className="spacer flex flex-col min-h-screen items-center justify-center">
        <FloatingIcons />
        <div className="text-6xl lg:text-7xl font-bold text-center">
          <div
            className="text-onBackground"
            style={{ lineHeight: 1.2 }}
            // data-aos="fade-in"
            // data-aos-duration={1500}
          >
            {signInLoading ? 'I am' : 'tired of'}
          </div>
          <div
            className="text-onSurface"
            data-aos="fade-left"
            data-aos-duration={1500}
          >
            {signInLoading ? 'relieved' : 'queues?'}
          </div>
        </div>
        <div className="mt-12 text-xl text-center px-4">
          No more standing in long queues to take printouts
        </div>

        <button
          className={`text-xl mt-20 text-onBackground px-10 py-5
      ${
        signInLoading
          ? 'bg-background text-primary border-primary shadow-lg shadow-[#c3c4d4]'
          : 'bg-primary text-onPrimary border-onBackgroundDull hover:bg-primaryDark hover:text-onPrimary'
      } rounded-full tracking-wide uppercase w-64 flex justify-center border
      `}
          disabled={signInLoading}
          onClick={onClickLogin}
        >
          {signInLoading ? <SmallLoading /> : 'Log In'}
        </button>
      </div>

      <div>
        {showSeeMore ? (
          <>
            {/* <button
              className="absolute bottom-2 right-2 lg:right-6 lg:bottom-6 px-6 py-2 rounded-md
          bg-onBackground text-background flex flex-row justify-between items-center animate-pulse hover:animate-none"
            >
              See how!
            </button> */}
            <div
              class="scroll-prompt hover:cursor-pointer"
              onClick={onClickHowWeWork}
            >
              <div class="scroll-prompt-arrow-container">
                <div class="scroll-prompt-arrow">
                  <div></div>
                </div>
                <div class="scroll-prompt-arrow">
                  <div></div>
                </div>
              </div>
            </div>
          </>
        ) : null}
        <div className="gallery" ref={stepsDivRef}>
          <div className="left">
            <div className="detailsWrapper">
              <div className="details d1" ref={step1DivRef}>
                <div className="flex flex-row gap-x-6">
                  <div className="flex w-10 h-10 text-xl justify-center items-center bg-onSurface text-background rounded-full mt-1 lg:mt-3">
                    1
                  </div>
                  <div className="flex flex-col flex-1">
                    <div
                      className="text-3xl md:text-3xl lg:text-5xl font-semibold leading-relaxed mb-4 md:mb-6"
                      style={{ lineHeight: 1.3 }}
                    >
                      Upload
                    </div>
                    <div className="leading-relaxed">
                      Upload your documents, customize, select a shop based on
                      your convenience and add to cart
                    </div>
                    <div
                      className="hidden lg:inline-flex items-center self-start px-6 py-3 mt-16 border border-onBackground rounded-full
                      hover:cursor-pointer hover:bg-[#f4c59c]"
                      onClick={() => moveToStep(step2DivRef)}
                      data-aos="fade-in"
                      data-aos-duration={1500}
                      data-aos-once="false"
                    >
                      Next
                      <IoArrowForward className="ml-2" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="details d2" ref={step2DivRef}>
                <div className="flex flex-row gap-x-6">
                  <div className="flex w-10 h-10 text-xl justify-center items-center bg-onSurface text-background rounded-full mt-1 lg:mt-3">
                    2
                  </div>
                  <div className="flex flex-col flex-1">
                    <div
                      className="text-3xl md:text-3xl lg:text-5xl font-semibold leading-relaxed mb-4 md:mb-6"
                      style={{ lineHeight: 1.3 }}
                    >
                      Pay{' '}
                    </div>
                    <div className="leading-relaxed">
                      After selecting the shop, pay via UPI or debit card, and
                      hooray! Your order is created
                    </div>
                    <div className="flex items-center gap-x-4 mt-16">
                      <div
                        className="hidden lg:inline-flex items-center self-start px-6 py-3 border border-[#636e72] text-[#636e72] hover:border-onBackground hover:text-onSurface rounded-full
                      hover:cursor-pointer hover:bg-[#a9b6f2]"
                        onClick={() => moveToStep(step1DivRef)}
                      >
                        <IoArrowBack className="mr-2" />
                        Previous
                      </div>
                      <div
                        className="hidden lg:inline-flex items-center self-start px-6 py-3 border border-onBackground rounded-full
                      hover:cursor-pointer hover:bg-[#a9b6f2]"
                        onClick={() => moveToStep(step3DivRef)}
                        data-aos="fade-in"
                        data-aos-duration={1500}
                        data-aos-once="false"
                      >
                        Next
                        <IoArrowForward className="ml-2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="details d3" ref={step3DivRef}>
                <div className="flex flex-row gap-x-6">
                  <div className="flex w-10 h-10 text-xl justify-center items-center bg-onSurface text-background rounded-full mt-1 lg:mt-3">
                    3
                  </div>
                  <div className="flex flex-col flex-1">
                    <div
                      className="text-3xl md:text-3xl lg:text-5xl font-semibold leading-relaxed mb-4 md:mb-6"
                      style={{ lineHeight: 1.3 }}
                    >
                      Collect
                    </div>
                    <div className="leading-relaxed">
                      Collect your printout from the shop once you get a
                      confirmation via Whatsapp/E-mail
                      <br />
                      <span className="font-semibold uppercase tracking-wide mt-1">
                        No delivery
                      </span>
                    </div>
                    <div
                      className="hidden lg:inline-flex items-center self-start px-6 py-3 mt-16 border border-[#636e72] text-[#636e72] hover:border-onBackground hover:text-onSurface rounded-full
                      hover:cursor-pointer hover:bg-[#86e4ad]"
                      onClick={() => moveToStep(step2DivRef)}
                    >
                      <IoArrowBack className="mr-2" />
                      Previous
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="right">
            <div className="photos">
              <div className="photos-box">
                <div className="photo col1">
                  <div className="scaleDiv transform transition duration-500 hover:scale-110">
                    <img src="Step 1 Image-min.png" alt="Step 1" />
                  </div>
                </div>
                <div className="photo col2">
                  <div className="scaleDiv transform transition duration-500 hover:scale-110">
                    <img src="Step 2 Image-min.png" alt="Step 2" />
                  </div>
                </div>
                <div className="photo col3">
                  {explode ? (
                    <div className="flex justify-center items-center">
                      <ConfettiExplosion />
                    </div>
                  ) : null}
                  <div className="scaleDiv transform transition duration-500 hover:scale-110">
                    <img src="Step 3 Image-min.png" alt="Step 3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref} className="-mt-10"></div>
      <Footer />
    </div>
  )
}

export default Landing
