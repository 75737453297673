import React, { useEffect, useRef, useState } from 'react'
import { CiShop } from 'react-icons/ci'
import DisplayOpenHours from './DisplayOpenHours'
import _ from 'lodash'
import { getCurrentDayIndex } from '../../utils/helper'
import { Rating } from '@smastrom/react-rating'
import { IoCallOutline } from 'react-icons/io5'

import '@smastrom/react-rating/style.css'
import RatingReview from './RatingReview'
import { getShop } from '../../API/root'

const ShopDetailsModal = ({
  onClose,
  shop_temp,
  from_my_orders,
  toggleShowRatingSubmittedModal,
}) => {
  const dayRefs = useRef([])

  const [shop, setShop] = useState(shop_temp)

  const address = `${shop['primary_address']}, ${shop['secondary_address']}`
  const open_hours = shop['open_hours']
  const [showRatingReview, setShowRatingReview] = useState(false)

  const timingsDivRef = useRef(null)

  const toggleRatingReview = () => {
    setShowRatingReview(!showRatingReview)
  }

  const getData = async () => {
    const data = await getShop({ shop_id: shop['id'] })
    setShop(data)
  }

  useEffect(() => {
    getData()
    if (from_my_orders) {
      const current_day_index = getCurrentDayIndex()
      setTimeout(() => {
        // if (timingsDivRef.current) {
        //   timingsDivRef.current.scrollIntoView({ behavior: 'smooth' })
        // }
        if (dayRefs.current[current_day_index]) {
          dayRefs.current[current_day_index].scrollIntoView({
            behavior: 'smooth',
          })
        }
      }, 1000)
    }
  }, [])

  const rating = shop['rating'] ? shop['rating'] : 0
  const rating_count = shop['rating_count'] ? shop['rating_count'] : null

  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="bg-background rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        {!showRatingReview ? (
          <div className="py-8">
            <div className="mb-4">
              <div
                className="inline-flex items-center font-semibold bg-primaryDull text-onSurface mr-4
            pl-3 pr-4 py-1 self-start rounded-r-full"
              >
                <CiShop className="mr-2 text-onSurface text-lg" />
                {shop['name']}
              </div>
              {!showRatingReview ? (
                <div
                  className="flex items-center mx-4 mt-3"
                  onClick={toggleRatingReview}
                >
                  <div className="flex items-center">
                    <Rating style={{ maxWidth: 100 }} value={rating} readOnly />
                    {rating_count ? (
                      <div className="text-sm mx-2 inline-block text-onBackground">
                        ({rating_count})
                      </div>
                    ) : null}
                  </div>
                  <div className="text-link text-sm px-2 underline hover:cursor-pointer">
                    Rate
                  </div>
                </div>
              ) : null}
            </div>

            <div className="h-screen-2 overflow-y-auto">
              <div className="px-6">
                {shop.no_of_pending_orders && shop.no_of_pending_orders > 0 ? (
                  <div className="mt-1 font-semibold text-sm">
                    {shop.no_of_pending_orders} in queue
                  </div>
                ) : null}
                <div className="mt-2">{shop['primary_address']}</div>
                <div className="mt-1 text-sm">{shop['secondary_address']}</div>
                <a
                  href={`https://www.google.com/maps?q=${address}`}
                  target="_blank"
                >
                  <div className="text-link underline text-sm mt-2 inline-block">
                    View on map
                  </div>
                </a>
                {shop['phone'] ? (
                  <div>
                    <a href={`tel:${shop['phone']}`}>
                      <div className="mt-2 inline-flex flex-row items-center text-sm pr-2">
                        <IoCallOutline className="pr-2 text-xl" />
                        {shop['phone']}
                      </div>
                    </a>
                  </div>
                ) : null}
                {!_.isEmpty(open_hours) ? (
                  <div ref={timingsDivRef}>
                    <DisplayOpenHours
                      open_hours={open_hours}
                      dayRefs={dayRefs}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex items-center pt-6 px-6 border-t border-t-surface">
              <button
                className="mr-4 w-full border border-onBackground text-onSurface rounded-md py-4 text-center
            hover:bg-surface"
                onClick={onClose}
              >
                Close
              </button>
              {/* {from_my_orders && shop['has_notes'] ? (
                <Link
                  to={`/shops/${shop.id}`}
                  key={shop.id}
                  state={{
                    shop: shop,
                    parent_id: null,
                    path: [],
                  }}
                  className="w-full border border-primary bg-primary text-onPrimary rounded-md py-4 text-center
              hover:bg-primaryDark hover:border-primaryDark"
                  style={{ textDecoration: 'none' }}
                >
                  <button>Re-Order</button>
                </Link>
              ) : null} */}
            </div>
          </div>
        ) : (
          <RatingReview
            goBack={toggleRatingReview}
            onClose={onClose}
            shop={shop}
            toggleShowRatingSubmittedModal={toggleShowRatingSubmittedModal}
          />
        )}
      </div>
    </div>
  )
}

export default ShopDetailsModal
