import { Rating } from '@smastrom/react-rating'
import React, { useEffect, useState } from 'react'
import { getUserRatingForShop, rateShop } from '../../API/root'
import { UserAuth } from '../AuthContext'
import { FiChevronLeft } from 'react-icons/fi'
import SmallLoading from './SmallLoading'
import _ from 'lodash'

const MAX_REVIEW_LENGTH = 200

const allowSubmit = ({ rating, reivew }) => {
  if (rating >= 1 && rating <= 5) {
    return true
  }
  return false
}

const RatingReview = ({
  shop,
  onClose,
  goBack,
  toggleShowRatingSubmittedModal,
}) => {
  const { user } = UserAuth()
  const user_id = user['uid']
  const shop_id = shop['id']

  const [isLoading, setIsLoading] = useState(false)
  const [rating, setRating] = useState(0)
  const [review, setReview] = useState('')

  const onSubmit = async () => {
    if (!allow_submit) {
      return
    }
    setIsLoading(true)
    const review_temp = review ? review.trim() : null
    let data = {
      rating,
    }
    if (review_temp) {
      data['review'] = review_temp
    }
    await rateShop({ shop_id, user_id, data })
    setIsLoading(false)
    onClose()
    setTimeout(() => {
      toggleShowRatingSubmittedModal()
    }, 500)
  }

  const getData = async () => {
    const data = await getUserRatingForShop({ user_id, shop_id })
    if (!data) {
      return
    }
    setRating(data['rating'])
    if (data['review']) {
      setReview(data['review'])
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const review_trim = review ? review.trim() : null

  const allow_submit = allowSubmit({ rating, review })

  let button_style = allow_submit
    ? 'border-primary bg-primary text-onPrimary hover:bg-primaryDark hover:border-primaryDark'
    : 'border-surface text-onBackground bg-surface'

  return (
    <div>
      <div
        className="mx-4 mt-4 w-10 h-10 bg-surface flex justify-center items-center
          rounded-full hover:cursor-pointer hover:bg-onBackgroundDull"
        onClick={goBack}
      >
        <FiChevronLeft className="text-onSurface w-5 h-5" />
      </div>
      <div className="px-10 mt-4 pb-4">
        <div className="flex justify-center mb-2">
          <Rating
            style={{ maxWidth: 150 }}
            value={rating}
            onChange={setRating}
          />
        </div>
        <textarea
          className="mt-4 px-4 py-2 h-screen-30 border border-onBackgroundDull rounded-md w-11/12
          focus:outline-none focus:ring focus:border-primary"
          placeholder="Your review"
          maxLength={MAX_REVIEW_LENGTH}
          onChange={(e) => setReview(e.target.value)}
          value={review}
        />
        <div className="text-sm text-onBackground mb-4 mt-1">
          {_.size(review_trim)} / {MAX_REVIEW_LENGTH}
        </div>
      </div>
      <div className="flex items-center pt-6 px-6 border-t border-t-surface pb-8">
        <button
          className={`flex justify-center w-full border rounded-md py-4 text-center ${button_style}`}
          onClick={onSubmit}
          disabled={!allow_submit}
        >
          {isLoading ? <SmallLoading /> : 'Submit'}
        </button>
      </div>
    </div>
  )
}

export default RatingReview
