import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { addDeviceToken } from '../API/root'
import { messaging, onMessageListener } from '../API/firebase'
import { getToken } from 'firebase/messaging'

const NotificationComponent = () => {
  const user = useSelector((state) => state.root_user)
  const [notification, setNotification] = useState({ title: '', body: '' })
  const notify = () => toast(<ToastDisplay />)
  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    )
  }

  useEffect(() => {
    if (notification?.title) {
      notify()
    }
  }, [notification])

  useEffect(() => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        })
          .then(async (currentToken) => {
            if (currentToken) {
              await addDeviceToken({
                collectionName: 'Shops',
                documentId: user.admin_at,
                token: currentToken,
              })
            } else {
              alert('Allow notification in settings')
            }
          })
          .catch((err) => {})
      }
    })
  }, [])

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      })
    })
    .catch((err) => console.log('failed: ', err))

  return <Toaster />
}

export default NotificationComponent
