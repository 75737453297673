import _ from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import { FiPlusCircle, FiTrash2 } from 'react-icons/fi'
import TimePicker from 'react-time-picker'
import { DAYS } from '../../utils/constants'

const OpenHours = ({ openHours, setOpenHours, toggleDisplayOpenHours }) => {
  const [days, setDays] = useState(openHours)

  const onChangeAllDay = (index) => {
    let daysTemp = _.cloneDeep(days)
    if (!daysTemp[index].all_day) {
      daysTemp[index].open = []
    }
    daysTemp[index].all_day = !daysTemp[index].all_day
    setDays(daysTemp)
  }

  const addOpenHours = (index) => {
    let daysTemp = _.cloneDeep(days)
    daysTemp[index].open.push({ start: '08:00', end: '16:00' })
    setDays(daysTemp)
  }

  const onChangeStartTime = (time, index, open_index) => {
    let daysTemp = _.cloneDeep(days)
    daysTemp[index].open[open_index].start = time
    setDays(daysTemp)
  }
  const onChangeEndTime = (time, index, open_index) => {
    let daysTemp = _.cloneDeep(days)
    daysTemp[index].open[open_index].end = time
    setDays(daysTemp)
  }
  const removeOpenHours = (index, open_index) => {
    let daysTemp = _.cloneDeep(days)
    daysTemp[index].open.splice(open_index, 1)
    setDays(daysTemp)
  }

  const applyToAllDays = () => {
    const mondayData = _.cloneDeep(days[0])
    const daysTemp = _.map(days, (day) => {
      return {
        ...day,
        open: _.cloneDeep(mondayData.open),
      }
    })
    setDays(daysTemp)
  }

  const renderDays = () => {
    return _.map(days, (day, index) => {
      const checkboxClass = day.all_day
        ? ' bg-secondary border-secondary'
        : ' bg-background border-onSurface'
      return (
        <div
          className="py-6 border border-surface px-4 py-2 rounded-md mb-4"
          key={day.day}
        >
          <div className="font-semibold text-onSurface">{day.day}</div>
          {!day.all_day ? (
            _.size(day.open) > 0 ? (
              <>
                {_.map(day.open, (time, open_index) => {
                  const startM = moment(time['start'], 'h:mm a')
                  const endM = moment(time['end'], 'h:mm a')
                  let diffHours = endM.diff(startM, 'hours')
                  let diffMinutes = endM.diff(startM, 'minutes')
                  diffHours =
                    diffHours < 0 ? 24 - Math.abs(diffHours) : diffHours
                  diffMinutes = diffMinutes % 60

                  return (
                    <div className="py-1" key={`${day.day}, ${open_index}`}>
                      <TimePicker
                        onChange={(time) =>
                          onChangeStartTime(time, index, open_index)
                        }
                        value={time.start}
                        disableClock
                        clearIcon={null}
                        startLabel="From"
                        className="text-onSurface border rounded-full px-2 py-2 border-onBackgroundDull"
                      />
                      <span className="text-onSurface mx-4">to</span>
                      <TimePicker
                        onChange={(time) =>
                          onChangeEndTime(time, index, open_index)
                        }
                        value={time.end}
                        disableClock
                        clearIcon={null}
                        startLabel="To"
                        className="text-onSurface border rounded-full px-2 py-2 border-onBackgroundDull"
                      />
                      <span className="text-onBackground text-sm ml-2">
                        ({diffHours}h
                        {diffMinutes > 0 ? ` ${diffMinutes} m` : null})
                      </span>
                      <span
                        onClick={() => removeOpenHours(index, open_index)}
                        className="inline-flex mt-4 mb-2 items-center cursor-pointer"
                      >
                        <FiTrash2 className="text-onSurface ml-4 inline" />
                        <span className="text-onSurface underline ml-2">
                          Remove
                        </span>
                      </span>
                    </div>
                  )
                })}
              </>
            ) : null
          ) : null}
          {!day.all_day ? (
            <div className="flex flex-1 mt-4 mb-2 items-center">
              <FiPlusCircle className="text-secondary inline" />
              <span
                onClick={() => addOpenHours(index)}
                className="text-secondary cursor-pointer pl-2"
              >
                Add open hours
              </span>
            </div>
          ) : null}
          {index == 0 ? (
            <div
              className="mt-4 text-link cursor-pointer text-sm underline"
              onClick={applyToAllDays}
            >
              Apply Monday timings to all days
            </div>
          ) : null}
          {/* <span
            onClick={() => onChangeAllDay(index)}
            className="inline-flex items-center cursor-pointer"
          >
            <div
              className={`w-4 h-4 inline-flex justify-center items-center rounded-sm border ${checkboxClass}`}
            >
              {day.all_day ? (
                <FiCheck className="text-onPrimary text-sm" />
              ) : null}
            </div>
            <span className="text-onSurface ml-3 cursor-pointer">
              Open 24 hours
            </span>
          </span> */}
        </div>
      )
    })
  }

  const save = () => {
    setOpenHours(days)
    toggleDisplayOpenHours()
  }

  return (
    <div
      data-aos="zoom-out"
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="flex justify-center">
        <div className="bg-background mt-10 py-4 rounded-xl w-11/12 md:w-3/4 lg:w-1/2 xl:w-2/5">
          <div className="px-10 pt-8">
            <div className="text-xl text-onSurface font-semibold">
              {_.isEqual(openHours, DAYS) ? 'Add' : 'Update'} open hours
            </div>
            <div className="text-onBackground pb-6 text-sm mt-1">
              Days without hours added will display as closed
            </div>
          </div>
          <div className="h-screen-3/4 md:h-screen-2 overflow-y-auto px-6">
            <div className="px-4">
              <div className="mt-4">{renderDays()}</div>
            </div>
          </div>
          <div className="flex justify-between pt-8 px-6 mb-2 border-t border-t-surface">
            <button
              className="flex flex-1 justify-center border border-onBackground text-onBackground px-8 py-3 mr-4 rounded-md
              hover:bg-surface"
              onClick={toggleDisplayOpenHours}
            >
              Cancel
            </button>
            <button
              className="flex flex-1 justify-center border border-primary text-onPrimary bg-primary px-8 py-3 rounded-md
              hover:bg-primaryDark hover:border-primaryDark"
              onClick={save}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenHours
