import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'
import Cart from './components/Cart'
import Shops from './components/Shops'
import Login from './components/Login'
import Loading from './components/partials/Loading'
import MinifiedCart from './components/partials/MinifiedCart'
import Navbar from './components/partials/Navbar'
import Listing from './components/Listing'
import { useDispatch } from 'react-redux'
import { UserAuth } from './components/AuthContext'
import { getUser } from './API/root'
import { useEffect, useState } from 'react'
import OrderCreatedModal from './components/OrderCreatedModal'
import MyOrders from './components/MyOrders'
import Landing from './components/Landing'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Settings from './components/Settings'
import Orders from './components/Orders'
import AuthForm from './components/AuthForm'
import Statistics from './components/Statistics'
import Root from './components/Root'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import StudentNavbar from './components/partials/StudentNavbar'
import Profile from './components/Profile'
import Account from './components/Account'
import ReactGA from 'react-ga4'
import StudentBottomNavbar from './components/partials/StudentBottomNavbar'
import Offline from './components/Offline'
import NotificationComponent from './components/NotificationComponent'
import Home from './components/Home'
import ShopsForCustomDocuments from './components/ShopsForCustomDocuments'
import UserSideNavBar from './components/partials/UserSideNavBar'

function App() {
  const dispatch = useDispatch()
  const { user, googleSignIn, logOut, authLoading } = UserAuth()
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  let is_admin_route = false

  if (window.location.hostname.includes('admin')) {
    is_admin_route = true
  }

  const getUserData = async (user) => {
    const user_data = await getUser({ user_id: user.uid })
    dispatch({ type: 'ROOT_USER', data: user_data })
  }

  const handleScroll = (event) => {
    const { scrollTop } = event.target

    dispatch({ type: 'Y', data: scrollTop })
  }

  useEffect(() => {
    if (user) {
      getUserData(user)
    }
  }, [user])

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    })
  }, [])

  useEffect(() => {
    const handleOnline = () => setIsOnline(true)
    const handleOffline = () => setIsOnline(false)

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  if (!isOnline) {
    return <Offline />
  }

  if (authLoading) {
    return <Loading />
  }

  if (!authLoading && !user) {
    if (is_admin_route) {
      return <AuthForm />
    } else {
      return <Landing />
    }
  } else if (is_admin_route) {
    return (
      <Router>
        <ToastContainer />
        <Navbar />
        <div className="text-onSurface mb-20 md:mt-10 bg-background">
          <Routes>
            <Route path="/" element={<Root route="/orders" />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/analytics" element={<Statistics />} />
            <Route path="*" element={<Root route="/orders" />} />
          </Routes>
          <NotificationComponent />
        </div>
      </Router>
    )
  } else {
    return (
      <Router>
        <ToastContainer />
        <div className="flex w-full">
          <div
            className="text-onSurface pb-20
            lg:rounded-2xl bg-background w-full flex relative"
            onScroll={handleScroll}
          >
            <div className="flex w-full items-center ">
              <div className="hidden lg:block lg:w-1/5">
                <UserSideNavBar />
              </div>
              <div className="">
                <StudentNavbar />
              </div>
              <StudentBottomNavbar />
              <div className="w-full lg:flex lg:w-4/5 lg:justify-center">
                <div className="lg:w-3/4 lg:mt-6 lg:ml-6">
                  <Routes>
                    <Route path="/" element={<Root route="/home" />} />
                    <Route path="/home" element={<Home />} />
                    <Route
                      path="/select-shop"
                      element={<ShopsForCustomDocuments />}
                    />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/my-orders" element={<MyOrders />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/shops" element={<Shops />} />
                    <Route path="/shops/:shopId" element={<Listing />} />
                    <Route
                      path="/shops/:shopId/:listingId"
                      element={<Listing />}
                    />
                    <Route
                      path="/order-created"
                      element={<OrderCreatedModal />}
                    />
                    <Route
                      path="*"
                      element={
                        // <main style={{ padding: '1rem' }}>
                        //   <p>There's nothing here!</p>
                        // </main>
                        <Root route="/shops" />
                      }
                    />
                  </Routes>
                </div>
              </div>
            </div>
            <MinifiedCart />
          </div>
        </div>
      </Router>
    )
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  )
}

export default App
