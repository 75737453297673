import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteAccount, getUser, submitUserDetails } from '../API/root'
import DeleteAccountModal from './partials/DeleteAccountModal'
import { UserAuth } from './AuthContext'
import { useNavigate } from 'react-router-dom'
import LogoutModal from './partials/LogoutModal'
import UserDetailsModal from './partials/UserDetailsModal'

const Account = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const navbar_height = useSelector((state) => state.navbar_height)
  const root_user = useSelector((state) => state.root_user)
  const { token, logOut, user } = UserAuth()

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [isUserDetailsModalVisible, setIsUserDetailsModalVisible] =
    useState(false)

  const onLogout = () => {
    dispatch({ type: 'LOGOUT' })
    logOut()
    navigate('/')
  }

  const onDeleteAccount = async () => {
    try {
      await deleteAccount({ token })
      alert('Account Deleted Successfully')
      toggleDeleteAcountModal()
    } catch (error) {
      toggleDeleteAcountModal()
    }
  }

  const onSubmitUserDetails = async ({ phone }) => {
    const data = {
      phone,
    }
    await submitUserDetails({ user_id: root_user['id'], data })
    dispatch({ type: 'ROOT_USER', data: { ...root_user, phone } })
    toggleUserDetailsModal()
  }

  const toggleDeleteAcountModal = () => {
    setShowDeleteAccountModal(!showDeleteAccountModal)
  }

  const toggleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal)
  }

  const toggleUserDetailsModal = () => {
    setIsUserDetailsModalVisible(!isUserDetailsModalVisible)
  }

  const getData = async () => {
    const data = await getUser({ user_id: user.uid })
    dispatch({ type: 'ROOT_USER', data })
  }

  useEffect(() => {
    if (!root_user) {
      getData()
    }
  }, [])

  return (
    <div style={{ marginTop: `${navbar_height + 2}px` }}>
      {root_user ? (
        <div className="flex justify-center pt-4 mb-8">
          <div className="flex flex-col justify-center items-center w-1/2">
            <div className="w-20 h-20 rounded-full flex justify-center items-center bg-primary text-onPrimary text-2xl uppercase tracking-widest">
              {root_user['abbrevation']}
            </div>
            <div className="mt-4">{root_user['email']}</div>
            <div className="mt-2">
              {root_user['phone'] ? <span>+91 {root_user['phone']} </span> : ''}
              <span
                className="p-2 text-sm text-link hover:cursor-pointer"
                onClick={toggleUserDetailsModal}
              >
                {root_user['phone'] ? 'Edit' : 'Add phone'}
              </span>
            </div>
          </div>
        </div>
      ) : null}
      <div className="py-2 lg:flex lg:gap-x-10 lg:mt-20">
        <div
          className="px-4 py-4 border-b lg:border border-onBackgrounDull w-full hover:bg-surface hover:cursor-pointer lg:rounded-md lg:text-center"
          onClick={toggleLogoutModal}
        >
          Logout
        </div>
        <div
          className="px-4 py-4 border-b lg:border border-onBackgrounDull w-full hover:bg-surface hover:cursor-pointer lg:rounded-md lg:text-center text-error"
          onClick={toggleDeleteAcountModal}
        >
          Delete Account
        </div>
      </div>
      {showLogoutModal ? (
        <LogoutModal onClose={toggleLogoutModal} onConfirm={onLogout} />
      ) : null}
      {showDeleteAccountModal ? (
        <DeleteAccountModal
          onClose={toggleDeleteAcountModal}
          onConfirm={onDeleteAccount}
        />
      ) : null}
      {isUserDetailsModalVisible ? (
        <UserDetailsModal
          onClose={toggleUserDetailsModal}
          onConfirm={onSubmitUserDetails}
        />
      ) : null}
    </div>
  )
}

export default Account
