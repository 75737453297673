import _ from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SmallLoading from './SmallLoading'

const UserDetailsModal = ({ onClose, onConfirm }) => {
  const root_user = useSelector((state) => state.root_user)

  const navigate = useNavigate()
  const [phone, setPhone] = useState(root_user.phone ? root_user.phone : '')
  const [isLoading, setIsLoading] = useState(false)

  const onChangePhone = (e) => {
    const value = e.target.value
    const formattedValue = value.replace(/\D/g, '').trim()
    if (_.size(formattedValue) <= 10) {
      setPhone(formattedValue)
    }
  }

  const onSubmit = async () => {
    if (allow_submit) {
      setIsLoading(true)
      await onConfirm({ phone })
      setIsLoading(false)
    }
  }

  const allow_submit = _.size(phone) == 10 ? true : false
  const submit_button_style = allow_submit
    ? 'border-primary bg-primary text-onPrimary hover:bg-primaryDark hover:border-primaryDark'
    : 'border-surface text-onBackground bg-surface'

  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        <div>
          <div className="text-xl text-onSurface font-semibold mb-6">
            Details
          </div>
          <span className="text-onBackground">+91</span>
          <input
            type="tel"
            pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
            className="ml-2 w-1/2 px-4 py-2 border border-onBackgroundDull rounded-md mb-2 focus:outline-none focus:ring focus:border-primary"
            value={phone}
            onChange={onChangePhone}
          />
          <div className="mt-4 font-semibold bg-warningBackground text-warning px-4 py-2 rounded-md mb-4">
            Make sure you have updated Whatsapp and have accepted their Terms of
            Service and Privacy Policy.
          </div>
          <div className="my-2">
            Please provide your Whatsapp number to send a message once the order
            is <span className="font-semibold">READY</span>
          </div>
          <div className="mt-8 mb-2 flex flex-row items-center">
            <button
              className="h-12 flex border border-onBackground text-onBackground px-8 py-3 mr-4 rounded-md
              hover:bg-surface"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className={`${submit_button_style} h-12 flex items-center border px-8 rounded-md`}
              onClick={onSubmit}
              disabled={!allow_submit}
            >
              {isLoading ? <SmallLoading /> : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDetailsModal
