import _ from 'lodash'
import moment from 'moment/moment'
import React from 'react'
import {
  getCurrentDayIndex,
  isPlaceOpenNow,
  timingsMeta,
} from '../../utils/helper'

const DisplayOpenHours = ({ open_hours, dayRefs }) => {
  const { is_open_now, closes_soon } = isPlaceOpenNow(open_hours)
  const { timings_text, timings_style } = timingsMeta({
    is_open_now,
    closes_soon,
  })

  const renderTimings = (open) => {
    if (_.isEmpty(open)) {
      return <span className="text-error">Closed</span>
    }
    return _.map(open, (entry, index) => {
      const formatted_start = moment(entry.start, ['HH:mm']).format('h:mm a')
      const formatted_end = moment(entry.end, ['HH:mm']).format('h:mm a')
      return (
        <div key={index}>
          {formatted_start} - {formatted_end}
        </div>
      )
    })
  }

  const current_day_index = getCurrentDayIndex()

  return (
    <div className="mt-8 mb-6">
      <div className="flex items-center mb-4">
        <div className="font-semibold uppercase">Timings</div>
        <div
          className={`px-3 py-1 rounded-full ml-4 text-sm hover:cursor-pointer ${timings_style}`}
        >
          {timings_text}
        </div>
      </div>

      {_.map(open_hours, (entry, index) => {
        const { day, open } = entry

        return (
          <div
            className={`inline-flex mb-2 w-full px-4 rounded-md ${
              current_day_index == index
                ? 'py-2 border border-primary rounded-md'
                : ''
            }`}
            key={day}
            ref={(el) => (dayRefs.current[index] = el)}
          >
            <div className="w-1/2">{day}</div>
            <div>{renderTimings(open)}</div>
          </div>
        )
      })}
    </div>
  )
}

export default DisplayOpenHours
