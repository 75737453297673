export const getAbbrevation = (user) => {
  let abbrevation = null
  if (user['first_name']) {
    if (user['last_name']) {
      const ln_split = user.last_name.split(' ')
      abbrevation = `${user.first_name[0].toUpperCase()}${ln_split[
        ln_split.length - 1
      ][0].toUpperCase()}`
    } else {
      abbrevation = user.first_name[0].toUpperCase()
    }
  }
  return abbrevation
}
