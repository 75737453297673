import React from 'react'
import Lottie from 'react-lottie-player'
import animationData from '../../assets/animations/document-loading.json'

const UploadingFilesModal = () => {
  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="flex flex-col justify-center items-center bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        <div className="-mt-10">
          <Lottie
            loop
            animationData={animationData}
            play
            style={{ width: 200, height: 200 }}
          />
        </div>
        <div className="font-semibold -mt-6">Uploading documents</div>
        <div className="text-onBackground mt-2 text-center">
          Your documents will be deleted once the order is received by you
        </div>
      </div>
    </div>
  )
}

export default UploadingFilesModal
