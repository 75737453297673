import {
  collection,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  doc,
  arrayUnion,
} from 'firebase/firestore'
import { getTimestamp } from '../utils/helper'
import { db } from './firebase'
import axios from 'axios'
import _ from 'lodash'
import { getAbbrevation } from './User'

export const readDocument = async ({ collectionName, documentId }) => {
  let res = []
  try {
    const docRef = doc(db, collectionName, documentId)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      res = docSnap.data()
    } else {
      console.log('No such doc!')
    }
  } catch (e) {
    console.log(e)
  }
  return res
}

export const readDocumentsFromCollection = async ({ collectionName }) => {
  let res = []
  try {
    const q = query(collection(db, collectionName))
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((doc) => {
      let doc_data = doc.data()
      doc_data['id'] = doc.id
      res.push(doc_data)
    })
  } catch (e) {
    console.log(e)
  }
  return res
}

export const getShopsList = async ({ has_notes }) => {
  let res = []
  try {
    const q = has_notes
      ? query(
          collection(db, 'Shops'),
          where('allow_orders', '==', true),
          where('has_notes', '==', true)
        )
      : query(collection(db, 'Shops'), where('allow_orders', '==', true))
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((doc) => {
      let doc_data = doc.data()
      doc_data['id'] = doc.id
      res.push(doc_data)
    })
  } catch (e) {
    console.log(e)
  }
  return res
}

export const readUserOrders = async ({ collectionName }) => {
  let res = []
  let shop_id_data = {}
  try {
    const q = query(
      collection(db, collectionName),
      where('payment_status', '==', 'READY')
    )
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(async (doc) => {
      let doc_data = doc.data()
      doc_data['id'] = doc.id
      res.push(doc_data)
    })
    for (let entry of res) {
      const { shop_id } = entry
      let shop = {}
      if (_.has(shop_id_data, shop_id)) {
        shop = shop_id_data[shop_id]
      } else {
        shop = await getShop({ shop_id })
        shop_id_data[shop_id] = shop
      }

      entry['shop'] = shop
    }
  } catch (e) {}
  return res
}

export const readDocumentsFromCollectionWithParentId = async ({
  collectionName,
  parentId,
}) => {
  let res = []
  try {
    const q = query(
      collection(db, collectionName),
      where('parent_id', '==', parentId)
    )
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((doc) => {
      let doc_data = doc.data()
      doc_data['id'] = doc.id
      res.push(doc_data)
    })
  } catch (e) {
    console.log(e)
  }
  return res
}

export const getUser = async ({ user_id }) => {
  let res = null
  try {
    const docRef = doc(db, 'Users', user_id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      res = docSnap.data()
      res['abbrevation'] = getAbbrevation(res)
    }
  } catch (e) {
    console.log(e)
  }
  return res
}
export const getShop = async ({ shop_id }) => {
  let res = null
  try {
    const docRef = doc(db, 'Shops', shop_id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      res = docSnap.data()
      res['id'] = docSnap.id
    }
  } catch (e) {
    console.log(e)
  }
  return res
}

export const uploadFiles = async ({ formData, token }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL}/uploadNotes`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  } catch (error) {}
}

export const createNotes = async ({ token, files }) => {
  const data = { files }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL}/createNotesFromFiles`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
  } catch (error) {
    console.error('Error uploading files', error)
  }
}

export const createOrder = async ({ token, data }) => {
  let res = {}
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL}/createOrder`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    res = response.data
  } catch (error) {
    console.log(error)
    alert(error.response.data.message)
  }
  return res
}

export const updateDocument = async ({ collectionName, documentId, data }) => {
  let { server_timestamp, local_timestamp } = getTimestamp()
  data = {
    ...data,
    modified_date: server_timestamp,
  }
  await updateDoc(doc(db, collectionName, documentId), data)
}

export const getStatisticsData = async ({ token }) => {
  let res = {}
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL}/getAllStatistics`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    res = response.data
  } catch (error) {
    console.error('Error: ', error)
  }
  return res
}

export const getAmountForDateRange = async ({ token, data }) => {
  let res = {}
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL}/getAmountForDateRange`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    res = response.data
  } catch (error) {
    console.error('Error: ', error)
  }
  return res
}

export const deleteAccount = async ({ token }) => {
  let res = null
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL}/deleteAccount`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    res = response.data
  } catch (error) {}
  return res
}

export const updateShopDetails = async ({ shop_id, data }) => {
  let { server_timestamp } = getTimestamp()
  data = {
    ...data,
    modified_date: server_timestamp,
  }

  try {
    await setDoc(doc(db, 'Shops', shop_id), data, { merge: true })
  } catch (e) {}
}

export const rateShop = async ({ shop_id, user_id, data }) => {
  let { server_timestamp } = getTimestamp()
  data = {
    ...data,
    modified_date: server_timestamp,
  }

  try {
    await setDoc(doc(db, `Shops/${shop_id}/ratings`, user_id), data, {
      merge: true,
    })
  } catch (e) {
    console.log(e)
  }
}

export const getUserRatingForShop = async ({ shop_id, user_id }) => {
  let res = null
  try {
    const docRef = doc(db, `Shops/${shop_id}/ratings`, user_id)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      res = docSnap.data()
    }
  } catch (e) {
    console.log(e)
  }
  return res
}

export const submitUserDetails = async ({ user_id, data }) => {
  let { server_timestamp } = getTimestamp()
  data = { ...data, modified_date: server_timestamp }
  try {
    await setDoc(doc(db, 'Users', user_id), data, {
      merge: true,
    })
  } catch (e) {
    console.log(e)
  }
}

export const getS3PutSignedURLs = async ({ token, file_names }) => {
  let result = []
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL}/getS3PutSignedURLs`,
      {
        file_names,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const res = response.data
    result = res['result']
  } catch (error) {
    console.log(error)
  }
  return result
}

export const uploadFileToS3PutSignedURL = async ({ file, url }) => {
  const response = await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
  })
  if (response.status != 200) {
    throw new Error(`Failed to upload file: ${file.name}`)
  }
}

export const addDeviceToken = async ({ collectionName, documentId, token }) => {
  const doc_ref = doc(db, collectionName, documentId)
  await updateDoc(doc_ref, {
    device_tokens: arrayUnion(token),
  })
}
