import { useContext, createContext, useState, useEffect } from 'react'
import {
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
  onIdTokenChanged,
} from 'firebase/auth'
import { auth, db } from '../API/firebase'
import { useDispatch } from 'react-redux'
import { doc, onSnapshot } from 'firebase/firestore'
import { getAbbrevation } from '../API/User'

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [user, setUser] = useState(null)
  const [signInLoading, setSignInLoading] = useState(false)
  const [authLoading, setAuthLoading] = useState(true)
  const [token, setToken] = useState(null)

  const googleSignIn = async () => {
    try {
      setTimeout(() => {
        setSignInLoading(true)
      }, 400)
      const provider = new GoogleAuthProvider()
      await signInWithPopup(auth, provider)
      setSignInLoading(false)
    } catch (error) {
      setSignInLoading(false)
      if (error.code == 'auth/popup-blocked') {
        alert('Popups are blocked. Please enable popups to log in.')
      }
    }
  }

  const logOut = async () => {
    setAuthLoading(true)
    await signOut(auth)
    setAuthLoading(false)
  }

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (currentUser) => {
      setAuthLoading(true)
      if (currentUser) {
        const token_temp = await currentUser.getIdToken()
        const unsub = onSnapshot(doc(db, 'Users', currentUser.uid), (doc) => {
          let user_data = doc.data()
          if (user_data) {
            user_data['id'] = currentUser.uid
            user_data['abbrevation'] = getAbbrevation(user_data)
            dispatch({ type: 'ROOT_USER', data: user_data })
          }
        })
        setToken(token_temp)
        setUser(currentUser)
      } else {
        setUser(null)
      }
      setAuthLoading(false)
    })
    return () => unsubscribe()
  }, [user])

  return (
    <AuthContext.Provider
      value={{ user, token, googleSignIn, logOut, authLoading, signInLoading }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const UserAuth = () => {
  return useContext(AuthContext)
}
