import * as firebase from 'firebase/app'
import { getMessaging, onMessage } from 'firebase/messaging'

import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  connectAuthEmulator,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_CENTER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)

// const app = firebase.initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const messaging = getMessaging(app)

// export { firebase, db };

export const auth = getAuth()
if (process.env.REACT_APP_MODE == 'local' || process.env.APP_DEV) {
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectAuthEmulator(auth, 'http://localhost:9099')
}

const provider = new GoogleAuthProvider()

export const signInWithGoogle = async () => {
  let res = {}
  try {
    const result = await signInWithPopup(auth, provider)
    const credential = GoogleAuthProvider.credentialFromResult(result)
    const token = credential.accessToken
    const user = result.user
  } catch (error) {
    if (error.code == 'auth/popup-blocked') {
      alert('Popups are blocked. Please enable popups and try again.')
    }
    res = {}
  }
  return res
}

export const signOutGoogle = () => {
  auth
    .signOut()
    .then(() => {
      return
    })
    .catch((error) => {
      console.warn(error.message)
    })
}

export const emailSignIn = async ({ email, password }) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (error) {
    const errorCode = error.code
    switch (errorCode) {
      case 'auth/invalid-email':
        alert('Invalid email address.')
        break
      case 'auth/user-disabled':
        alert('User account has been disabled.')
        break
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        alert('Invalid email or password.')
        break
      default:
        alert('An error occurred. Please try again.')
        break
    }
  }
}

export const emailSignUp = async ({ email, password }) => {
  try {
    const newUserCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )
    await sendEmailVerification(newUserCredential.user)
  } catch (error) {
    const errorCode = error.code
    switch (errorCode) {
      case 'auth/invalid-email':
        alert('Invalid email address.')
        break
      case 'auth/user-disabled':
        alert('User account has been disabled.')
        break
      case 'auth/weak-password':
        alert('Invalid password.')
        break
      default:
        alert('Account already exists')
        break
    }
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
