import React from 'react'
import { getDocumentURL } from './AdminDocumentItem'

const DocumentPreviewModal = ({ doc, onClose }) => {
  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="flex flex-col items-center bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        {doc['file']['type'] != 'application/pdf' ? (
          <img src={getDocumentURL(doc['key'])} className="max-h-screen-2" />
        ) : null}
        <button
          className="w-full mt-10 border border-onBackground text-onSurface rounded-md py-4 text-center
            hover:bg-surface"
          onClick={() => onClose(null)}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default DocumentPreviewModal
