import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { UserAuth } from '../AuthContext'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import LogoutModal from './LogoutModal'

const Navbar = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { logOut } = UserAuth()

  const navRef = useRef(null)
  const [showLogoutModal, setShowLogoutModal] = useState(false)

  useEffect(() => {
    if (navRef.current) {
      const divHeight = navRef.current.clientHeight
      dispatch({ type: 'NAVBAR_HEIGHT', data: divHeight })
    }
  }, [])

  if (
    !(
      // location.pathname.match('/') ||
      (
        location.pathname.match('/orders') ||
        location.pathname.match('/analytics') ||
        location.pathname.match('/settings')
      )
    )
  ) {
    return null
  }

  const toggleLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal)
  }

  const onLogout = () => {
    dispatch({ type: 'LOGOUT' })
    logOut()
    navigate('/')
  }

  return (
    <>
      <nav
        className="bg-background flex fixed top-0 left-0 w-full text-onSurface border-b border-b-surface"
        ref={navRef}
      >
        <div className="flex flex-row space-between items-center">
          <div className="w-full flex justify-center space-x-4">
            <NavLink
              to={`/orders`}
              style={{ textDecoration: 'none' }}
              className="self-center"
            >
              <img src="logo-primary.png" className="h-8 self-center px-4" />
            </NavLink>
            <NavLink
              to={`/orders`}
              style={{ textDecoration: 'none' }}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                isActive
                  ? 'text-onSurface border-b-2 px-6 py-2 mr-2 mt-2 cursor-pointer border-b-primary'
                  : 'text-onBackground px-6 py-2 mr-2 mt-2 cursor-pointer border-b-surface'
              }
            >
              Orders
            </NavLink>
            <NavLink
              to={`/analytics`}
              style={{ textDecoration: 'none' }}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                isActive
                  ? 'text-onSurface border-b-2 px-6 py-2 mr-2 mt-2 cursor-pointer border-b-primary'
                  : 'text-onBackground px-6 py-2 mr-2 mt-2 cursor-pointer border-b-surface'
              }
            >
              Analytics
            </NavLink>
            <NavLink
              to={`/settings`}
              style={{ textDecoration: 'none' }}
              style={{ display: 'flex' }}
              className={({ isActive }) =>
                isActive
                  ? 'text-onSurface border-b-2 px-6 py-2 mr-2 mt-2 cursor-pointer border-b-primary'
                  : 'text-onBackground px-6 py-2 mr-2 mt-2 cursor-pointer border-b-surface'
              }
            >
              Settings
            </NavLink>
          </div>
          <div
            className="fixed right-0 mx-6 text-onBackground hover:cursor-pointer"
            onClick={toggleLogoutModal}
          >
            Logout
          </div>
        </div>
      </nav>
      {showLogoutModal ? (
        <LogoutModal onClose={toggleLogoutModal} onConfirm={onLogout} />
      ) : null}
    </>
  )
}

export default Navbar
