// AuthForm.js
import React, { useState } from 'react'
import { emailSignUp, emailSignIn } from '../API/firebase'
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import _ from 'lodash'
import SmallLoading from './partials/SmallLoading'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const AuthForm = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [isSignIn, setIsSignIn] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleSignIn = async () => {
    try {
      setIsLoading(true)
      await emailSignIn({ email, password })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleSignUp = async () => {
    try {
      setIsLoading(true)
      await emailSignUp({ email, password })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const toggleLoginType = () => {
    setIsSignIn(!isSignIn)
  }

  const PasswordIcon = showPassword ? IoMdEye : IoMdEyeOff
  const allow_submit =
    !_.isEmpty(email) &&
    emailRegex.test(email) &&
    !_.isEmpty(password) &&
    !isLoading

  // if (isLoading) {
  //   return <Loading />
  // }

  return (
    <div className="mt-10 mb-36">
      <form onSubmit={handleSignIn}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto bg-[#121212]"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          // style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
        >
          <div className="flex flex-col items-center justify-center mt-24">
            <img src="logo-primary.png" className="h-24 self-center mb-10" />
            <div className="bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-1/2 xl:w-1/3 2xl:1/4 shadow-md shadow-onBackground">
              <div>
                <div className="text-xl text-onSurface font-semibold mb-10">
                  {isSignIn ? 'Welcome Back!' : 'New Account'}
                </div>
                <input
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-onBackgroundDull w-full px-6 py-4 rounded-md text-onSurface outline-none mb-6"
                />
                <div className="flex flex-row space-between items-center mb-6 rounded-md border border-onBackgroundDull">
                  <input
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-6 py-4 rounded-md text-onSurface outline-none"
                  />
                  <div
                    className="px-4 py-2 hover:cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <PasswordIcon className="text-onSurface" />
                  </div>
                </div>
                <button
                  type="submit"
                  className={`w-full ${
                    allow_submit
                      ? 'bg-primary text-onPrimary hover:bg-primaryDark'
                      : 'bg-surface text-onSurface'
                  } rounded-md py-4 mt-10 text-center flex justify-center`}
                  onClick={isSignIn ? handleSignIn : handleSignUp}
                  disabled={!allow_submit}
                >
                  {isLoading ? (
                    <SmallLoading />
                  ) : isSignIn ? (
                    'Log In'
                  ) : (
                    'Create Account'
                  )}
                </button>
              </div>

              <div
                className="mt-10 text-link hover:cursor-pointer text-sm text-right"
                onClick={toggleLoginType}
              >
                {isSignIn
                  ? "Don't have an account? Click here"
                  : 'Already have an account? Click here'}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AuthForm
