import { collection, onSnapshot, query, where } from 'firebase/firestore'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { db } from '../API/firebase'
import { updateDocument } from '../API/root'
import { getTimestamp, isEmpty, showToast } from '../utils/helper'
import Loading from './partials/Loading'
import { useSelector } from 'react-redux'
import { FiSearch } from 'react-icons/fi'
import { FiX } from 'react-icons/fi'
import AdminOrderItem from './partials/AdminOrderItem'
import OrderReceivedConfirmModal from './partials/OrderReceivedConfirmModal'
import Relax from '../assets/images/relax.svg'
import AdminDocumentItem from './partials/AdminDocumentItem'

const activeTabStyle = 'bg-primary text-onPrimary'
const inActiveTabStyle = 'bg-surface text-onBackground'
const activeCountStyle = 'bg-onPrimary text-onSurface'
const inActiveCountStyle = 'bg-onBackgroundDull text-onSurface'

const RelaxPlaceHolder = ({ text }) => {
  return (
    <div className="mt-20 md:mt-0 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center">
      <img src={Relax} className="w-3/4 md:w-1/3 lg:w-1/4" data-aos="fade-in" />
      <div className="mt-8 tracking-widest uppercase text-onBackground">
        {text}
      </div>
    </div>
  )
}

export const handlePrint = (p) => {
  // window.open(p, '_blank')
}

const Orders = () => {
  const searchRef = useRef(null)

  const user = useSelector((state) => state.root_user)
  const navbar_height = useSelector((state) => state.navbar_height)

  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [allOrders, setAllOrders] = useState({})
  const [pendingOrders, setPendingOrders] = useState([])
  const [readyOrders, setReadyOrders] = useState([])
  const [receivedOrders, setReceivedOrders] = useState([])
  const [activeTab, setActiveTab] = useState('PENDING')
  const [orderReceivedConfirmModalOpen, setOrderReceivedConfirmModalOpen] =
    useState(false)
  const [activeOrder, setActiveOrder] = useState(null)

  const filterOrders = (value) => {
    if (activeTab != 'READY') {
      return
    }
    const orders_temp = _.cloneDeep(allOrders[activeTab])
    if (isEmpty(value)) {
      updateOrdersMethod(orders_temp)
    } else {
      const valueLower = value.toLowerCase().trim()
      let filtered_orders = []

      filtered_orders = _.filter(orders_temp, (order) => {
        const token_number = order.token ? order.token.split('_')[1] : ''
        return token_number.includes(valueLower)
        // order.razorpay_order_id.toLowerCase().includes(valueLower) ||
        // order.email.toLowerCase().includes(valueLower)
      })
      updateOrdersMethod(filtered_orders)
    }
  }

  const onChangeInput = (e) => {
    const value = e.target.value
    setSearch(value)
    filterOrders(value)
  }

  const onClickReceived = async () => {
    onCloseReceivedModal()
    const order = { ...activeOrder }
    let next_status = order.status === 'PENDING' ? 'READY' : 'RECEIVED'
    let data = {
      status: next_status,
    }
    let { server_timestamp } = getTimestamp()
    if (next_status == 'RECEIVED') {
      data['received_date'] = server_timestamp
    }
    await updateDocument({
      collectionName: `Shops/${order.shop_id}/orders/`,
      documentId: order.id,
      data,
    })
    showToast({
      message: next_status,
      background_color: '#b2d9b2',
      color: '#2a472a',
    })
  }

  const onClickOrderStatus = async (order) => {
    setActiveOrder(order)
    // setIsLoading(true)
    let next_status = order.status === 'PENDING' ? 'READY' : 'RECEIVED'
    if (next_status == 'RECEIVED') {
      onCloseReceivedModal()
      return
    }
    let { server_timestamp } = getTimestamp()

    let data = {
      status: next_status,
    }
    if (next_status == 'READY') {
      data['ready_date'] = server_timestamp
    } else if (next_status == 'RECEIVED') {
      data['received_date'] = server_timestamp
    }

    await updateDocument({
      collectionName: `Shops/${order.shop_id}/orders/`,
      documentId: order.id,
      data,
    })
    showToast({
      message: next_status,
      background_color: '#b2d9b2',
      color: '#2a472a',
    })
    // setIsLoading(false)
  }

  const getData = async ({ user }) => {
    if (!user.admin_at) {
      return
    }
    const q = query(
      collection(db, `Shops/${user.admin_at}/orders`),
      where('payment_status', '==', 'READY'),
      where('status', '!=', 'RECEIVED')
    )
    onSnapshot(q, (querySnapshot) => {
      let pending_orders_temp = []
      let ready_orders_temp = []
      let received_orders_temp = []
      let all_orders_temp = {}
      querySnapshot.forEach((doc) => {
        let doc_data = doc.data()
        doc_data['id'] = doc.id
        const modules = doc_data['modules']

        doc_data['created_date_timestamp'] = doc_data['created_date']
          .toDate()
          .getTime()
        const status = doc_data['status']
        if (status === 'PENDING') {
          pending_orders_temp.push(doc_data)
        } else if (status === 'READY') {
          ready_orders_temp.push(doc_data)
        } else {
          received_orders_temp.push(doc_data)
        }
      })
      pending_orders_temp = _.sortBy(
        pending_orders_temp,
        'created_date_timestamp'
      )
      ready_orders_temp = _.sortBy(ready_orders_temp, 'created_date_timestamp')
      received_orders_temp = _.sortBy(
        received_orders_temp,
        'created_date_timestamp'
      )
      all_orders_temp['PENDING'] = pending_orders_temp
      all_orders_temp['READY'] = ready_orders_temp
      all_orders_temp['RECEIVED'] = received_orders_temp
      setPendingOrders(pending_orders_temp)
      setReadyOrders(ready_orders_temp)
      setReceivedOrders(received_orders_temp)
      setAllOrders(all_orders_temp)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (user) {
      getData({ user })
    }
  }, [user])

  let orders = []
  let buttonText = null
  let updateOrdersMethod = null

  if (activeTab === 'PENDING') {
    orders = pendingOrders
    buttonText = 'Ready'
    updateOrdersMethod = setPendingOrders
  } else if (activeTab === 'READY') {
    orders = readyOrders
    buttonText = 'Received'
    updateOrdersMethod = setReadyOrders
  } else {
    orders = receivedOrders
    updateOrdersMethod = setReceivedOrders
  }

  const date = new Date()
  const current_date = moment(date.getTime()).format('DD/MM/YY')

  const pendingTabStyle =
    activeTab === 'PENDING' ? activeTabStyle : inActiveTabStyle
  const readyTabStyle =
    activeTab === 'READY' ? activeTabStyle : inActiveTabStyle
  const receivedTabStyle =
    activeTab === 'RECEIVED' ? activeTabStyle : inActiveTabStyle

  const pendingCountStyle =
    activeTab === 'PENDING' ? activeCountStyle : inActiveCountStyle
  const readyCountStyle =
    activeTab === 'READY' ? activeCountStyle : inActiveCountStyle
  const ordersSize = _.size(orders)

  if (isLoading) {
    return <Loading />
  }

  const setTab = (tab) => {
    setActiveTab(tab)
    if (!_.isEmpty(search)) {
      filterOrders(search)
    }
  }

  const clearSearch = () => {
    setSearch('')
    filterOrders('')
  }

  const onCloseReceivedModal = () => {
    setOrderReceivedConfirmModalOpen(!orderReceivedConfirmModalOpen)
  }

  const pending_orders_len = _.size(pendingOrders)
  const ready_orders_len = _.size(readyOrders)

  const search_empty = _.isEmpty(search)

  const renderEmpty = () => {
    if (activeTab == 'PENDING' && pending_orders_len == 0) {
      return <RelaxPlaceHolder text="No Pending Orders" />
    } else if (activeTab == 'READY' && ready_orders_len == 0) {
      return <RelaxPlaceHolder text="All Orders Received" />
    }
    return null
  }

  return (
    <div className="mt-20 mb-20">
      <div className="flex inline-flex justify-start mx-10">
        <div className="bg-surface rounded-full p-1">
          <div
            className={`mr-2 cursor-pointer inline-flex justify-between items-center py-1 w-36 pl-4 rounded-full text-center ${pendingTabStyle}`}
            onClick={() => setTab('PENDING')}
          >
            <div>Pending</div>
            <div
              className={`rounded-full w-8 h-8 inline-flex justify-center items-center ml-2 mr-1 ${pendingCountStyle}`}
            >
              {pending_orders_len}
            </div>
          </div>
          <div
            className={`cursor-pointer inline-flex justify-between items-center  py-1 w-36 pl-4 rounded-full text-center ${readyTabStyle}`}
            onClick={() => setTab('READY')}
          >
            <div>Ready</div>
            <div
              className={`rounded-full w-8 h-8 inline-flex justify-center items-center ml-2 mr-1 ${readyCountStyle}`}
            >
              {ready_orders_len}
            </div>
          </div>
        </div>
      </div>
      {activeTab != 'PENDING' && (!search_empty || ready_orders_len > 0) ? (
        <div className="flex flex-1 justify-start mx-10">
          <div
            className="flex space-between items-center border border-onBackgroundDull inline-flex
        inline-block pl-4 rounded-md w-full md:w-1/3 lg:w-1/4 mt-6"
          >
            <div className="flex flex-1 items-center w-full">
              <div className="w-6 h-6 flex justify-center items-center hover:cursor-pointer">
                <FiSearch
                  className={
                    search_empty ? 'text-onBackgroundDull' : 'text-onBackground'
                  }
                  onClick={() => {
                    searchRef?.current.focus()
                  }}
                />
              </div>
              <form
                onSubmit={() => filterOrders(search)}
                className="w-full mx-4"
              >
                <input
                  type="text"
                  className="py-2 focus:outline-none w-full"
                  placeholder="Token number"
                  value={search}
                  onChange={onChangeInput}
                  ref={searchRef}
                />
              </form>
            </div>

            {!search_empty ? (
              <div
                className="mr-4 flex justify-center items-center bg-surface w-6 h-6 rounded-full
                  hover:bg-onBackground hover:text-background hover:cursor-pointer"
                onClick={clearSearch}
              >
                <FiX />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {renderEmpty()}
      {ordersSize > 0 ? (
        <div
          className="bg-background sticky pt-1 mt-6"
          style={{ top: `${navbar_height + 1}px` }}
        >
          <div
            className="mx-10 border border-onBackgroundDull flex flex-row gap-4
          bg-surface text-onSuface px-6 py-4 rounded-t-md font-semibold
           uppercase"
          >
            <div className="w-1/6">Order ID</div>
            <div className="w-1/2 pl-4">DOCUMENTS</div>
            <div className="w-1/6">Action</div>
            <div className="w-1/6">Amount</div>
          </div>
        </div>
      ) : null}

      {_.map(orders, (order, index) => {
        const isLast = index == ordersSize - 1 ? true : false
        const modules = order['modules']
        if (_.size(modules) > 0 && modules[0]['path']) {
          return (
            <AdminOrderItem
              order={order}
              index={index}
              buttonText={buttonText}
              current_date={current_date}
              onClickOrderStatus={onClickOrderStatus}
              key={order['id']}
              isLast={isLast}
            />
          )
        } else {
          return (
            <AdminDocumentItem
              order={order}
              index={index}
              buttonText={buttonText}
              current_date={current_date}
              onClickOrderStatus={onClickOrderStatus}
              key={order['id']}
              isLast={isLast}
            />
          )
        }
      })}
      {orderReceivedConfirmModalOpen ? (
        <OrderReceivedConfirmModal
          onClose={onCloseReceivedModal}
          onConfirm={onClickReceived}
          order={activeOrder}
        />
      ) : null}
    </div>
  )
}

export default Orders
