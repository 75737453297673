import { NavLink, useLocation } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FiHome, FiShoppingCart, FiUser } from 'react-icons/fi'
import { useTour } from '@reactour/tour'
import { getCartMeta } from '../../API/Cart'

const common_class = 'flex flex-1 justify-center items-center h-full'
const active_class = `${common_class} border-t-primary text-onSurface`
const inactive_class = `${common_class} border-t-background text-onBackground`

const StudentBottomNavbar = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { isOpen } = useTour()
  const cart = useSelector((state) => state.cart)
  const { no_of_items } = getCartMeta({ cart })

  const navRef = useRef(null)

  useEffect(() => {
    if (navRef.current) {
      const divHeight = navRef.current.clientHeight
      dispatch({ type: 'BOTTOM_NAVBAR_HEIGHT', data: divHeight })
    }
  }, [])

  const { pathname } = location

  if (pathname == '/cart' || pathname == 'select-shop') {
    return null
  }

  return (
    <nav
      className={`${
        !isOpen ? 'z-10' : ''
      } lg:hidden bg-background flex fixed bottom-0 left-0 w-full text-onSurface border-b border-b-surface`}
      ref={navRef}
    >
      <div
        className="w-full h-14 flex flex-1 justify-center border-t border-t-onBackgrounDull"
        // shadow-[#dbd7d7_0px_10px_10px_10px] shadow-xl
      >
        <NavLink
          exact="true"
          to="/"
          end
          style={{ textDecoration: 'none' }}
          className={({ isActive }) =>
            isActive ? active_class : inactive_class
          }
        >
          <div className="h-full w-full flex flex-col justify-center items-center">
            {pathname == '/home' ? (
              <div className="w-1/3 h-1 bg-primary absolute top-0 rounded-full"></div>
            ) : null}
            <FiHome className="text-lg" />
          </div>
        </NavLink>
        <NavLink
          to={`/cart`}
          style={{ textDecoration: 'none' }}
          style={{ display: 'flex' }}
          className={({ isActive }) =>
            isActive ? active_class : inactive_class
          }
        >
          <FiShoppingCart className="text-lg" />
          {no_of_items > 0 ? (
            <div className="ml-3 w-8 h-8 rounded-full flex justify-center items-center bg-primary text-onPrimary text-sm">
              {no_of_items}
            </div>
          ) : null}
        </NavLink>
        <NavLink
          to={`/profile`}
          style={{ textDecoration: 'none' }}
          style={{ display: 'flex' }}
          className={({ isActive }) =>
            isActive ? active_class : inactive_class
          }
        >
          <div className="h-full w-full flex flex-col justify-center items-center">
            {pathname == '/profile' ? (
              <div className="w-1/3 h-1 bg-primary absolute top-0 rounded-full"></div>
            ) : null}
            <FiUser className="text-lg" />
          </div>
        </NavLink>
      </div>
    </nav>
  )
}

export default StudentBottomNavbar
