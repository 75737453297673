import React, { useRef, useEffect } from 'react'
import { MdOutlineCloudUpload } from 'react-icons/md'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { AiOutlinePrinter } from 'react-icons/ai'
import { FiShoppingCart } from 'react-icons/fi'
import { IoDocumentOutline } from 'react-icons/io5'
import _ from 'lodash'

const DATA = [
  {
    top: 0.1,
    left: 0.2,
    icon: MdOutlineCloudUpload,
    color: '#8e44ad',
    bg: '#e9d8f0',
    rotate: 'rotate-6',
    aos_animation: 'fade-right',
  },
  {
    top: 0.5,
    left: 0.1,
    icon: IoDocumentOutline,
    color: '#c0392b',
    bg: '#f5d5d2',
    rotate: '-rotate-12',
    aos_animation: 'fade-up',
  },
  {
    top: 0.8,
    left: 0.3,
    icon: FiShoppingCart,
    color: '#d35400',
    bg: '#ffdbc3',
    rotate: '-rotate-3',
    aos_animation: 'fade-up-left',
  },
  {
    top: 0.3,
    left: 0.7,
    icon: AiOutlineClockCircle,
    color: '#2c3e50',
    bg: '#cdd8e4',
    rotate: 'rotate-45',
    aos_animation: 'fade-down',
  },
  {
    top: 0.6,
    left: 0.8,
    icon: AiOutlinePrinter,
    color: '#16a085',
    bg: '#c5f7ed',
    rotate: 'rotate-3',
    aos_animation: 'fade-down-left',
  },
]

export const FloatingIcons = () => {
  const iconRefs = useRef([])

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      iconRefs.current.forEach((icon, index) => {
        const speed = index * 0.2 + 0.2
        let y = scrollTop * speed
        y = y > 200 ? 200 : y
        icon.style.transform = `translateY(-${y}px)`
      })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="hidden lg:block">
      {_.map(DATA, (entry, index) => {
        // var randomnumber = Math.floor(Math.random() * (DATA.length - 0 + 1)) + 0

        const ICON = entry['icon']
        return (
          <div
            className={`random-position w-20 h-20 flex justify-center items-center rounded-md border-l-8 hover:animate-pulse`}
            data-aos={entry['aos_animation']}
            // data-aos="fade-in"
            data-aos-delay={(index + 1) * 300}
            data-aos-duration={500}
            style={{
              top: `calc(100vh * ${entry['top']})`,
              left: `calc(100vw * ${entry['left']})`,
              backgroundColor: entry['bg'],
              borderLeftColor: entry['color'],
            }}
            key={index.toString()}
            ref={(el) => (iconRefs.current[index] = el)}
          >
            <ICON
              className="text-4xl ${text_color"
              style={{ color: entry['color'] }}
            />
          </div>
        )
      })}
    </div>
  )
}
