import React from 'react'
import Lottie from 'react-lottie-player'
import animationData from '../assets/animations/no-wifi.json'

const Offline = () => {
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <Lottie
        loop={false}
        animationData={animationData}
        play
        style={{ width: 200, height: 200 }}
        segments={[0, 140]}
      />
      <div className="uppercase tracking-widest text-onBackground mt-4">
        No Internet
      </div>
    </div>
  )
}

export default Offline
