import _ from 'lodash'
import { useEffect, useState } from 'react'
import { readUserOrders } from '../API/root'
import Loading from './partials/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { UserAuth } from './AuthContext'
import MyOrderItem from './partials/MyOrderItem'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import ShopDetailsModal from './partials/ShopDetailsModal'
import { CiCircleQuestion } from 'react-icons/ci'
import OrderStatusInfoModal from './partials/OrderStatusInfoModal'
import { useTour } from '@reactour/tour'
import RatingSubmittedModal from './RatingSubmittedModal'
import noOrdersAnimationData from '../assets/animations/no-orders.json'
import Lottie from 'react-lottie-player'
import MyDocumentItem from './partials/MyDocumentItem'
import CreatedOrderCustomizationViewModal from './partials/CreatedOrderCustomizationViewModal'

const MyOrders = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { setIsOpen, setSteps } = useTour()

  const navbar_height = useSelector((state) => state.navbar_height)

  const [isLoading, setIsLoading] = useState(true)
  const { user, token } = UserAuth()
  const [orders, setOrders] = useState([])
  const [selected_document, setSelectedDocument] = useState(null)
  const [selectedShop, setSelectedShop] = useState(null)
  const [showOrderStatusInfo, setShowOrderStatusInfo] = useState(null)
  const [showRatingSubmittedModal, setShowRatingSubmittedModal] =
    useState(false)
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(null)

  const getData = async () => {
    let orders_temp = await readUserOrders({
      collectionName: `Users/${user.uid}/orders`,
    })
    orders_temp = _.sortBy(orders_temp, 'created_date').reverse()
    setIsLoading(false)
    setOrders(orders_temp)
  }

  const toggleShowRatingSubmittedModal = () => {
    setShowRatingSubmittedModal(!showRatingSubmittedModal)
  }

  const toggleOrderStatusInfo = () => {
    setSelectedOrderStatus(null)
    setShowOrderStatusInfo(!showOrderStatusInfo)
  }

  const onClickOrderStatus = (order) => {
    toggleOrderStatusInfo()
    setSelectedOrderStatus(order.status)
  }

  const toggleShopDetailsModal = () => {
    setSelectedShop(null)
  }

  useEffect(() => {
    getData()
  }, [])

  const orders_len = _.size(orders)

  const checkForTourGuide = () => {
    if (orders_len > 0) {
      if (localStorage.getItem('tour_guide_shown') != 'true') {
        setTimeout(() => {
          setIsOpen(true)
          localStorage.setItem('tour_guide_shown', 'true')
        }, 500)
      }
    }
  }

  useEffect(() => {
    checkForTourGuide()
  }, [orders])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div style={{ marginTop: `${navbar_height + 2}px` }}>
      <div className="pt-2 pb-10">
        {orders_len == 0 ? (
          <div className="flex flex-1 flex-col justify-center items-center min-h-screen -mt-40">
            <Lottie
              animationData={noOrdersAnimationData}
              play
              style={{ width: 250, height: 250 }}
              loop={false}
            />
            <div className="z-50 -mt-12 text-onBackground flex justify-center items-center uppercase tracking-widest">
              No Orders
            </div>
            <NavLink to={`/`} style={{ textDecoration: 'none' }}>
              <div className="bg-primary text-onPrimary px-6 py-3 rounded-md my-2 cursor-pointer mt-8 hover:bg-primaryDark">
                Browse
              </div>
            </NavLink>
          </div>
        ) : (
          <div>
            <div className="flex justify-between items-center mt-1 mb-4">
              <div className="ml-4 text-onBackground text-sm">
                {orders_len} order{orders_len != 1 ? 's' : ''}{' '}
              </div>
              <div
                className="mr-4 px-2 py-1 hover:cursor-pointer"
                onClick={toggleOrderStatusInfo}
              >
                <CiCircleQuestion className="text-lg" />
              </div>
            </div>
            {showOrderStatusInfo ? (
              <OrderStatusInfoModal
                onClose={toggleOrderStatusInfo}
                status={selectedOrderStatus}
              />
            ) : null}
            {_.map(orders, (order, index) => {
              const modules = order['modules']
              if (_.size(modules) > 0 && modules[0]['path']) {
                return (
                  <MyOrderItem
                    order={order}
                    index={index}
                    key={order['id']}
                    setSelectedShop={setSelectedShop}
                    onClickOrderStatus={onClickOrderStatus}
                  />
                )
              } else {
                return (
                  <MyDocumentItem
                    order={order}
                    index={index}
                    key={order['id']}
                    setSelectedShop={setSelectedShop}
                    onClickOrderStatus={onClickOrderStatus}
                    onClickDocument={setSelectedDocument}
                  />
                )
              }
            })}
          </div>
        )}
      </div>
      {selectedShop ? (
        <ShopDetailsModal
          onClose={toggleShopDetailsModal}
          shop_temp={selectedShop}
          from_my_orders
          toggleShowRatingSubmittedModal={toggleShowRatingSubmittedModal}
        />
      ) : null}
      {showRatingSubmittedModal ? (
        <RatingSubmittedModal onClickClose={toggleShowRatingSubmittedModal} />
      ) : null}
      {selected_document ? (
        <CreatedOrderCustomizationViewModal
          selected_document={selected_document}
          setSelectedDocument={setSelectedDocument}
        />
      ) : null}
    </div>
  )
}

export default MyOrders
