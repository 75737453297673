import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  LinearScale,
  BarElement,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Income in recent months',
    },
  },
}

const divideDataBy100 = (data) => {
  return data.map((entry) => entry / 100)
}

const getChartData = ({ labels, data }) => {
  return {
    labels,
    datasets: [
      {
        label: 'Income',
        data: divideDataBy100(data),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(51, 102, 204, 0.7)',
      },
    ],
  }
}

const BarChart = ({ stats }) => {
  const data = { ...stats }

  const labels = data
    ? data['amount_per_month_for_last_n_months']['months']
    : []
  const amounts = data
    ? data['amount_per_month_for_last_n_months']['amounts']
    : []

  return (
    <div className="h-screen-2 flex flex-2">
      <Bar options={options} data={getChartData({ labels, data: amounts })} />
    </div>
  )
}

export default BarChart
