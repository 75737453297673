import { useTour } from '@reactour/tour'
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronLeft, FiHome, FiUser } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWindowScroll } from 'react-use'
import ShopDetailsModal from './ShopDetailsModal'
import RatingSubmittedModal from '../RatingSubmittedModal'

const SCROLL_THRESHOLD = 15

const StudentNavbar = ({ title, Icon, IconNavigateTo, isModalOpen }) => {
  const { isOpen } = useTour()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  let shop_id = null,
    shop_name = null,
    parent_id = null,
    shop = null
  if (location && location.state) {
    shop = location.state.shop
    parent_id = location.state.parent_id
    if (shop) {
      shop_id = shop['id']
      shop_name = shop['name']
    }
  }

  const [showShopDetailsModal, setShowShopDetailsModal] = useState(false)
  const [showRatingSubmittedModal, setShowRatingSubmittedModal] =
    useState(false)

  const toggleShowRatingSubmittedModal = () => {
    setShowRatingSubmittedModal(!showRatingSubmittedModal)
  }

  const getTitle = () => {
    switch (location.pathname) {
      case '/':
        return 'Shops'
      case '/home':
        return 'Home'
      case '/shops':
        return 'Shops'
      case `/shops/${shop_id}`:
        return shop_name
      case `/shops/${shop_id}/${parent_id}`:
        return shop_name
      case `/cart`:
        return 'Cart'
      case `/profile`:
        return 'Profile'
      case `/my-orders`:
        return 'My Orders'
      case `/account`:
        return 'Account'
      default:
        return 'Shops'
    }
  }

  const getIconNavigateTo = () => {
    switch (location.pathname) {
      case '/profile':
        return '/home'
      case '/my-orders':
        return '/home'
      case '/account':
        return '/home'
      default:
        return '/profile'
    }
  }

  const getIcon = () => {
    switch (location.pathname) {
      case '/profile':
        return FiHome
      case '/my-orders':
        return FiHome
      case '/account':
        return FiHome
      default:
        return FiUser
    }
  }

  const navRef = useRef(null)

  const yl = useSelector((state) => state.y)

  const { y: scrollY } = useWindowScroll()
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    if (scrollY > SCROLL_THRESHOLD || yl > SCROLL_THRESHOLD) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }, [scrollY, yl])

  useEffect(() => {
    if (navRef.current) {
      const divHeight = navRef.current.clientHeight
      dispatch({ type: 'NAVBAR_HEIGHT', data: divHeight })
    }
  }, [])

  const I = getIcon()

  const onClickIcon = () => {
    const navigate_to = getIconNavigateTo()
    navigate(navigate_to)
  }

  const goBack = () => {
    navigate(-1)
  }

  const toggleShopDetailsModal = () => {
    setShowShopDetailsModal(!showShopDetailsModal)
  }

  const onClickTitle = () => {
    if (shop) {
      toggleShopDetailsModal()
    }
  }

  return (
    <div>
      <nav
        className={`${
          !isOpen ? 'z-10' : ''
        } px-4 pt-4 mb-8 pb-4 fixed top-0 w-full
      lg:w-4/5 lg:pl-6 bg-background ${isScrolled ? 'shadow-xl' : ''}`}
        ref={navRef}
      >
        <div className="flex w-full items-center space-around">
          {location.pathname == '/home' ? (
            <img
              src="/logo512.png"
              className="lg:hidden h-10 self-center pr-2 hover:cursor-pointer"
              onClick={() => navigate('/')}
            />
          ) : (
            <div className="mr-2">
              <div
                className="w-10 h-10 bg-surface flex justify-center items-center
          rounded-full hover:cursor-pointer hover:bg-onBackgroundDull"
                onClick={goBack}
              >
                <FiChevronLeft className="text-onSurface w-5 h-5" />
              </div>
            </div>
          )}
          <div
            className="font-semibold text-2xl lg:text-base lg:uppercase lg:tracking-wide lg:font-normal mx-4 truncate w-full"
            onClick={onClickTitle}
          >
            {getTitle()}
          </div>
          <div
            onClick={onClickIcon}
            className="lg:hidden text-lg text-onSurface flex justify-end hidden lg:inline-flex"
          >
            <div
              className="w-10 h-10 bg-surface flex justify-center items-center
          rounded-full hover:cursor-pointer hover:bg-onBackgroundDull"
            >
              <I className="text-onSurface w-5 h-5" />
            </div>
          </div>
        </div>
      </nav>
      {showRatingSubmittedModal ? (
        <RatingSubmittedModal onClickClose={toggleShowRatingSubmittedModal} />
      ) : null}
      {showShopDetailsModal ? (
        <ShopDetailsModal
          onClose={toggleShopDetailsModal}
          shop_temp={shop}
          toggleShowRatingSubmittedModal={toggleShowRatingSubmittedModal}
        />
      ) : null}
    </div>
  )
}

export default StudentNavbar
