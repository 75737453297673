import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const DeleteAccountModal = ({ onClose, onConfirm }) => {
  const navigate = useNavigate()
  const [text, setText] = useState('')

  const onChangeText = (e) => {
    setText(e.target.value)
  }

  const onDelete = () => {
    if (allow_delete) {
      onConfirm()
      navigate('/')
    }
  }

  const allow_delete = text == 'delete' ? true : false
  const delete_button_style = allow_delete
    ? 'border-error text-error bg-errorBackground hover:bg-errorBackgroundDark'
    : 'border-surface text-onBackground bg-surface'

  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        <div>
          <div className="text-xl text-onSurface font-semibold mb-6">
            Confirm
          </div>
          <input
            type="text"
            className="w-1/2 px-4 py-2 border border-onBackgroundDull rounded-md mb-2 focus:outline-none focus:ring focus:border-primary"
            value={text}
            onChange={onChangeText}
          />
          <div className="text-onBackground my-2">
            Type <span className="text-onSurface font-semibold">delete</span>{' '}
            inside the text box.
          </div>
          <p className="text-onBackground">
            You will no longer have access to your account.
          </p>
          <div className="mt-8 mb-2">
            <button
              className="border border-onBackground text-onBackground px-8 py-3 mr-4 rounded-md
              hover:bg-surface"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className={`${delete_button_style} border px-8 py-3 rounded-md`}
              onClick={onDelete}
              disabled={!allow_delete}
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteAccountModal
