import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { TourProvider } from '@reactour/tour'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { persistor, store } from './store/store'
import { AuthContextProvider } from './components/AuthContext'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga4'
import { tourguide_steps, tour_guide_styles } from './utils/constants'
import {
  tourGuidNextButton,
  tourGuidPrevButton,
  tourGuideCloseButton,
} from './utils/helper'

ReactGA.initialize('G-J3S3911R2E')

if (process.env.REACT_APP_MODE == 'prod') {
  Sentry.init({
    dsn: 'https://901694a3813387561dd583bf16000156@o4506870278062080.ingest.us.sentry.io/4506870282977280',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthContextProvider>
        <TourProvider
          steps={tourguide_steps}
          styles={tour_guide_styles}
          scrollSmooth
          prevButton={tourGuidPrevButton}
          nextButton={tourGuidNextButton}
          components={{
            Close: tourGuideCloseButton,
          }}
        >
          <App />
        </TourProvider>
      </AuthContextProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
