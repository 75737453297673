import React, { useEffect, useRef, useState } from 'react'
import { getShop, updateShopDetails } from '../API/root'
import { useSelector } from 'react-redux'
import { showToast } from '../utils/helper'
import Loading from './partials/Loading'
import OpenHours from './partials/OpenHours'
import _ from 'lodash'
import { DAYS } from '../utils/constants'
import DisplayOpenHours from './partials/DisplayOpenHours'
import CustomTextInput from './partials/CustomTextInput'

// title, value, optional, placeholer, onChange, maxLength, long

const allowSubmit = ({ sizes_types, shop }) => {
  _.forEach(sizes_types, (entry) => {
    const key = `${entry}_printout_cost`
    // if (!_.has(shop, key)) {
    //   return false
    // }
  })
  return true
}

const SettingsForm = () => {
  const user = useSelector((state) => state.root_user)
  const dayRefs = useRef([])
  const shop_id = user['admin_at']

  let openHoursInitial = _.cloneDeep(DAYS)

  const [displayOpenHours, showDisplayOpenHours] = useState(false)
  const [sizes_types, setSizesTypes] = useState([])
  const [open_hours, setOpenHours] = useState(openHoursInitial)
  const [shop, setShop] = useState(null)

  const [phone, setPhone] = useState(null)
  const [allow_orders, setAllowOrders] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  const handleToggle = () => {
    setAllowOrders(!allow_orders)
  }

  const toggleDisplayOpenHours = () => {
    showDisplayOpenHours(!displayOpenHours)
  }

  const onSubmit = async () => {
    if (!shop_id || !allow_submit) {
      return
    }
    setIsLoading(true)
    const data = {
      phone,
      sizes_types,
      allow_orders,
      open_hours: has_open_hours ? open_hours : [],
    }
    await updateShopDetails({ shop_id, data })
    setIsLoading(false)
    showToast({
      message: 'Updated',
      background_color: '#b2d9b2',
      color: '#2a472a',
      autoClose: 2500,
    })
    getData()
  }

  const onClickPrintType = (type) => {
    let sizes_types_temp = _.cloneDeep(sizes_types)
    if (_.includes(sizes_types, type)) {
      _.remove(sizes_types_temp, (item) => item == type)
    } else {
      sizes_types_temp.push(type)
    }
    sizes_types_temp = _.sortBy(sizes_types_temp)
    setSizesTypes(sizes_types_temp)
  }

  const renderPrintTypes = () => {
    const TYPES = [
      {
        name: 'A3 B/W',
        key: 'A3_bw',
      },
      {
        name: 'A3 Color',
        key: 'A3_color',
      },
      {
        name: 'A4 B/W',
        key: 'A4_bw',
      },
      {
        name: 'A4 Color',
        key: 'A4_color',
      },
    ]
    return (
      <div className="pb-6 border-b-onBackgroundDull">
        <div className="text-primary text-sm mb-4">Print Types</div>
        <div className="flex items-center">
          {_.map(TYPES, (type) => (
            <div className="flex items-center mr-6" key={type['key']}>
              <input
                checked={_.includes(sizes_types, type['key'])}
                id="checked-checkbox"
                type="checkbox"
                value=""
                className="w-6 h-6 text-primary bg-gray-100 border-onBackground rounded focus:ring-primary hover:cursor-pointer"
                onChange={() => onClickPrintType(type['key'])}
              />
              <label htmlFor="checked-checkbox" className="ml-2 text-onSurface">
                {type['name']}
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const getData = async () => {
    const shop_temp = await getShop({ shop_id })
    setShop(shop_temp)
    setIsLoading(false)
  }

  useEffect(() => {
    if (shop) {
      setPhone(shop.phone ? shop.phone : null)
      setAllowOrders(shop.allow_orders)
      setSizesTypes(shop.sizes_types ? shop.sizes_types : [])
      setOpenHours(
        !_.isEmpty(shop.open_hours) ? shop.open_hours : openHoursInitial
      )
    }
  }, [shop])

  useEffect(() => {
    if (shop_id) {
      getData()
    }
  }, [shop_id])

  if (!shop || isLoading) {
    return <Loading />
  }

  const has_open_hours = !_.isEqual(DAYS, open_hours)

  const data = {
    phone,
    sizes_types,
    allow_orders,
    open_hours: has_open_hours ? open_hours : [],
  }
  const shop_data = {
    phone: shop.phone,
    sizes_types: shop.sizes_types,
    allow_orders: shop.allow_orders,
    open_hours: shop.open_hours,
  }

  const has_diff = !_.isEqual(data, shop_data)
  const allow_submit = allowSubmit({ sizes_types, shop })

  return (
    <div className="flex">
      <div className="flex flex-col flex-1 mx-10">
        <div>
          {renderPrintTypes()}
          <div className="my-4">
            <div className="text-primary text-sm mb-4">Allow Orders</div>
            <label
              htmlFor="toggleSwitch"
              className={`relative cursor-pointer transition-colors duration-300 ${
                allow_orders ? 'bg-primary' : 'bg-onBackgroundDull'
              } rounded-full w-16 h-8 flex items-center`}
            >
              <input
                type="checkbox"
                id="toggleSwitch"
                className="opacity-0 absolute h-0 w-0"
                checked={allow_orders}
                onChange={handleToggle}
              />
              <span
                className={`absolute left-0 top-0 bg-white border border-gray-400 w-8 h-8 rounded-full transition-transform duration-300 transform ${
                  allow_orders ? 'translate-x-full' : ''
                }`}
              ></span>
            </label>
          </div>
          <div
            className="inline-block my-6 px-6 py-3 rounded-full
          border border-onBackgroundDull hover:cursor-pointer hover:bg-onBackground hover:text-background hover:border-onBackground"
            onClick={toggleDisplayOpenHours}
          >
            Update timings
          </div>
        </div>
        <CustomTextInput
          title="Phone number"
          placeholer="123456"
          value={phone}
          onChange={setPhone}
          type="number"
        />

        <div className="mt-4">
          <button
            value="Apply Changes"
            className={`${
              has_diff && allow_submit
                ? 'bg-primary text-onPrimary hover:bg-primaryDark'
                : 'bg-surface text-onSurface'
            } px-6 py-3 rounded-md`}
            onClick={onSubmit}
            disabled={!allow_submit || !has_diff}
          >
            Apply Changes
          </button>
        </div>
        {displayOpenHours ? (
          <OpenHours
            openHours={open_hours}
            setOpenHours={setOpenHours}
            toggleDisplayOpenHours={toggleDisplayOpenHours}
          />
        ) : null}
      </div>
      <div className="flex flex-1 mx-10 hidden md:block">
        <div className="px-10 shadow-md rounded-md border border-surface">
          <DisplayOpenHours open_hours={open_hours} dayRefs={dayRefs} />
        </div>
      </div>
    </div>
  )
}

export default SettingsForm
