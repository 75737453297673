import React, { useEffect, useState } from 'react'
import { FiCheck, FiClock, FiThumbsUp } from 'react-icons/fi'

const inactive_style = 'flex items-center mb-4 px-4 border border-background'

const OrderStatusInfoModal = ({ onClose, status }) => {
  const [showShadow, setShowShadow] = useState(false)

  useEffect(() => {
    if (status) {
      setTimeout(() => {
        setShowShadow(true)
      }, 400)
    }
  }, [])

  const active_style = `flex items-center py-2 px-4 mb-3 rounded-md duration-500 ease-in-out border ${
    showShadow ? 'border-surface shadow-md' : 'border-background'
  }`

  return (
    <div
      data-aos="zoom-out"
      className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div className="bg-background py-8 px-6 rounded-xl w-11/12 md:w-1/2 lg:w-screen-28">
        <div className="flex justify-between items-center mb-6">
          <div className="text-xl text-onSurface font-semibold">
            Order Status
          </div>
        </div>
        <div className={status == 'PENDING' ? active_style : inactive_style}>
          <div className="bg-onBackgroundDull rounded-full h-8 w-8 flex items-center justify-center mr-4">
            <FiClock className="text-onBackground text-sm" />
          </div>
          <div className="flex flex-col">
            <span className="font-medium text-onBackground">PENDING</span>
            <span className="font-medium text-onBackground text-xs">
              Order created, but not ready.
            </span>
          </div>
        </div>
        <div className={status == 'READY' ? active_style : inactive_style}>
          <div className="bg-successBackground rounded-full h-8 w-8 flex items-center justify-center mr-4">
            <FiCheck className="text-success text-sm" />
          </div>
          <div className="flex flex-col">
            <span className="font-medium text-success">READY</span>
            <span className="font-medium text-onBackground text-xs">
              Order ready, but not received by you.
            </span>
          </div>
        </div>
        <div
          className={
            status == 'RECEIVED'
              ? `${active_style} mb-0`
              : `${inactive_style} mb-0`
          }
        >
          <div className="bg-primaryDull rounded-full h-8 w-8 flex items-center justify-center mr-4">
            <FiThumbsUp className="text-primary text-sm" />
          </div>
          <div className="flex flex-col">
            <span className="font-medium text-primary">RECEIVED</span>
            <span className="font-medium text-onBackground text-xs">
              You have collected the printout.
            </span>
          </div>
        </div>
        <button
          className="w-full border border-onBackground rounded-md py-4 mt-8 text-center hover:bg-surface"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default OrderStatusInfoModal
