import React, { useState } from 'react'
import Datepicker from 'react-tailwindcss-datepicker'
import { getAmountForDateRange } from '../../API/root'
import { UserAuth } from '../AuthContext'
import { renderAmount } from '../../utils/helper'
import SmallLoading from './SmallLoading'

const StatisticsDate = () => {
  const { token } = UserAuth()

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [amount, setAmount] = useState(null)

  const handleValueChange = (newValue) => {
    setValue(newValue)
    setAmount(null)
  }

  const onSearch = async () => {
    if (!allow_search) return
    setIsLoading(true)
    const data = {
      start_date: value['startDate'],
      end_date: value['endDate'],
    }
    try {
      const response = await getAmountForDateRange({ token, data })
      setAmount(response['amount'])
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const allow_search = value['startDate'] && value['endDate'] && !isLoading

  return (
    <div className="w-full md:w-1/2 lg:w-full flex flex-1 py-10 justify-center border border-surface rounded-lg h-fit">
      <div className="w-full px-10">
        <>
          <div className="mb-4 text-onBackground uppercase tracking-wider">
            Custom dates
          </div>
          <Datepicker
            value={value}
            onChange={handleValueChange}
            showShortcuts={true}
            displayFormat={'DD-MM-YYYY'}
            inputClassName="bg-surfaceDull w-full px-4 py-2 rounded-md border border-surface"
          />

          <button
            className={`${
              allow_search
                ? 'bg-primary text-onPrimary hover:bg-primaryDark'
                : 'bg-surface text-onSurface'
            } px-6 py-2 rounded-md inline-block mt-8 mb-10 w-32 flex justify-center`}
            onClick={onSearch}
            disabled={!allow_search}
          >
            {isLoading ? <SmallLoading /> : 'Search'}
          </button>

          <div className="font-semibold text-3xl">
            {amount != null ? (
              <span>{renderAmount(amount)}</span>
            ) : (
              <span className="text-background invisible">Select date</span>
            )}
          </div>
        </>
      </div>
    </div>
  )
}
export default StatisticsDate
