import React from 'react'
import { IoTabletPortraitOutline } from 'react-icons/io5'
import { IoTabletLandscapeOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { renderAmount } from '../../utils/helper'

const CustomDocumentMinified = ({
  doc,
  index,
  deleteItem,
  setCustomDocument,
  shop,
  togglePreview,
}) => {
  const dispatch = useDispatch()
  const no_of_pages = doc['no_of_pages']
  const quantity = doc['quantity']

  const decreaseQuantity = () => {
    dispatch({ type: 'DECREASE_QUANTITY', key: doc.key, cost: 0 })
  }

  const increaseQuantity = () => {
    dispatch({ type: 'INCREASE_QUANTITY', key: doc.key, cost: 0 })
  }

  let cost = null
  // if (shop) {
  //   const printout_cost = getPrintoutCostForDocument({ shop, doc })
  //   cost = doc['quantity'] * Math.ceil(printout_cost * doc['no_of_pages'])
  // }

  return (
    <div className="px-6 flex justify-between border-b border-surface lg:hover:bg-surface hover:lg:rounded-md">
      <div className="flex justify-between items-center w-full gap-x-2">
        <div
          className="flex flex-col flex-1 whitespace-normal w-screen-2 overflow-hidden hover:cursor-pointer py-4"
          onClick={() => {
            setCustomDocument(doc)
          }}
        >
          <div className="mr-2 break-words overflow-hidden semi-bold">
            {doc['name']}
            <span className="pl-2 text-sm">
              ({no_of_pages} page{no_of_pages != 1 ? 's' : ''})
            </span>
          </div>
          <div className="mt-4 flex justify-between items-center">
            <div className="flex items-center gap-x-2">
              {doc['orientation'] == 'portrait' ? (
                <div>
                  <IoTabletPortraitOutline />
                </div>
              ) : (
                <div>
                  <IoTabletLandscapeOutline />
                </div>
              )}
              {doc['print_type'] == 'bw' ? (
                <img src="bw.png" className="w-4" />
              ) : (
                <img src="color.png" className="w-4" />
              )}
              {doc['no_of_pages'] > 1 ? (
                <div className="bg-onBackground text-background rounded-md px-2 py-1 text-sm">
                  {doc['print_style'] == 'b2b' ? 'Back to Back' : 'Single Side'}
                </div>
              ) : null}
              {doc['page_size'] ? (
                <div className="bg-onBackgroundDull text-onSurface rounded-md px-2 py-1 text-sm">
                  {doc['page_size']}
                </div>
              ) : null}
              {/* <button
                className="mx-2 bg-primaryDull text-onSurface px-4 py-2 rounded-md text-sm
          hover:bg-primaryDark hover:text-onPrimary"
              >
                Customize
              </button> */}
            </div>
          </div>
        </div>
        <div className="flex justify-start items-center gap-x-2">
          {cost ? (
            <div className="flex font-semibold mr-2">
              {renderAmount(cost, false)}
            </div>
          ) : null}
          <div className="flex flex-col items-end">
            <div className="w-24 px-2 py-1 rounded-md border border-onBackgroundDull text-success text-center hover:cursor-pointer lg:hover:bg-success lg:hover:text-background">
              <div className="flex flex-row justify-between font-semibold">
                <button
                  className="flex flex-1 justify-center"
                  onClick={() => decreaseQuantity()}
                >
                  -
                </button>
                <div className="flex flex-1 justify-center hover:cursor-default">
                  {quantity}
                </div>
                <button
                  className="flex flex-1 justify-center"
                  onClick={() => increaseQuantity()}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomDocumentMinified
