import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { getShopsList } from '../API/root'
import Loading from './partials/Loading'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import { Rating } from '@smastrom/react-rating'

const Shops = () => {
  const dispatch = useDispatch()
  const seachQuery = useSearchParams()[0]

  const navbar_height = useSelector((state) => state.navbar_height)
  const [isLoading, setIsLoading] = useState(true)
  const [shops, setShops] = useState([])

  const getData = async () => {
    let shopsTemp = await getShopsList({ has_notes: true })
    shopsTemp = _.sortBy(shopsTemp, 'name')
    // if (_.isEmpty(shopsTemp)) {
    //   //Caused first time during account creation as user record isn't created and security rule fails.
    //   setTimeout(async () => {
    //     shopsTemp = await getShopsList()
    //     shopsTemp = _.sortBy(shopsTemp, 'name')
    //     setShops(shopsTemp)
    //     setIsLoading(false)
    //   }, 3000)
    // } else {
    setShops(shopsTemp)
    setIsLoading(false)
    // }
  }

  const referenceNum = seachQuery.get('reference')

  useEffect(() => {
    if (referenceNum) {
      dispatch({ type: 'CLEAR_CART' })
    }
  }, [referenceNum])

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Shops',
    })
    getData()
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div style={{ marginTop: `${navbar_height + 16}px` }}>
      {/* <Heading title="Shops" Icon={FiUser} IconNavigateTo={'/my-orders'} /> */}
      {_.map(shops, (shop, index) => {
        const rating = shop['rating'] ? shop['rating'] : null
        const rating_count = shop['rating_count'] ? shop['rating_count'] : null
        return (
          <Link
            to={`/shops/${shop.id}`}
            key={shop.id}
            state={{
              shop: shop,
              parent_id: null,
              path: [],
            }}
            style={{ textDecoration: 'none' }}
          >
            <div
              className="border border-surface px-4 py-4 md:w-1/2 lg:w-11/12
                        inline-block mb-4 rounded-lg mx-4 cursor-pointer hover:bg-surface"
              key={index}
            >
              <div className="text-lg font-semibold">{shop.name}</div>
              {rating ? (
                <div className="flex items-center my-1">
                  <Rating style={{ maxWidth: 80 }} value={rating} readOnly />
                  {rating_count ? (
                    <div className="text-sm mx-2 inline-block text-onBackground">
                      ({rating_count})
                    </div>
                  ) : null}
                </div>
              ) : null}
              {shop.no_of_pending_orders && shop.no_of_pending_orders > 0 ? (
                <div className="mt-1 font-semibold text-sm">
                  {shop.no_of_pending_orders} in queue
                </div>
              ) : null}
              <div className="mt-2">{shop.primary_address}</div>
              <div className="mt-1 text-sm">{shop.secondary_address}</div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default Shops
