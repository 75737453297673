import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Root = ({ route }) => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate(route)
  }, [route])

  return null
}

export default Root
