export const Loading = () => {
  return (
    <div className="flex flex-1 justify-center items-center min-h-screen">
      <div className="w-12 h-12">
        <span className="h-3 w-3">
          <span className="animate-ping-slow absolute h-12 w-12 rounded-full bg-primary opacity-75"></span>
        </span>
      </div>
    </div>
  )
}

export default Loading
