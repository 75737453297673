import React from 'react'
import { CiShop } from 'react-icons/ci'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getCartMeta } from '../../API/Cart'

const ShopDetailsCart = ({ shop, toggleShopDetails }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)

  const { cart_type } = getCartMeta({ cart })

  const address = shop
    ? `${shop['primary_address']}, ${shop['secondary_address']}`
    : null

  if (!shop) {
    return (
      <>
        <button
          className="hidden fixed bottom-2 lg:bottom-12 bg-primary text-onPrimary w-11/12 lg:w-3/5  px-6 py-3
        lg:inline-flex flex-row justify-center items-center rounded-md lg:left-1/5 hover:cursor-pointer hover:bg-primaryDark"
          onClick={() => navigate('/select-shop')}
        >
          Select shop
        </button>
        <button
          className="lg:hidden fixed bottom-2 lg:bottom-12 bg-primary text-onPrimary w-11/12 lg:w-3/5  px-6 py-3
        inline-flex flex-row justify-center items-center rounded-md -translate-x-2/4 left-2/4 hover:cursor-pointer hover:bg-primaryDark"
          onClick={() => navigate('/select-shop')}
        >
          Select shop
        </button>
      </>
    )
  }

  return (
    <>
      <div
        className="bg-primaryDull flex items-center mb-4
         border-b border-t hover:bg-primaryDull2 hover:cursor-pointer lg:rounded-md"
        onClick={toggleShopDetails}
      >
        <div className="flex flex-col px-4 py-3">
          <div className="flex items-center font-semibold mb-2">
            <CiShop className="mr-2 text-lg" />
            <span className="line-clamp-1">{shop['name']}</span>
          </div>
          <div
            className="text-sm line-clamp-1"
            // className={`text-sm  ${showShopDetails ? '' : 'line-clamp-1'}`}
          >
            {address}
          </div>
        </div>
        {/* <a href={`https://www.google.com/maps?q=${address}`} target="_blank">
            <div className="p-4 px-6">
              <FaLocationArrow className="text-error" />
            </div>
          </a> */}
      </div>
      {cart_type == 'DOCUMENT' ? (
        <div
          className="text-link px-4 inline-block underline hover:cursor-pointer"
          onClick={() => {
            for (let item of cart) {
              // const data = { ...item, shop_id: shop['id'], shop }
              delete item['shop_id']
              delete item['shop']
              dispatch({ type: 'UPDATE_CART_ITEM', key: item.key, item })
            }
            navigate('/select-shop')
          }}
        >
          Change shop
        </div>
      ) : null}
    </>
  )
}

export default ShopDetailsCart
