import _ from 'lodash'

export const formatOrderModules = (modules) => {
  let formattedModules = {}
  let groupByModules = []
  _.forEach(modules, (module) => {
    const moduleArr = module.path
    const groupByArr = moduleArr.slice(0, moduleArr.length - 1)
    const group_by = groupByArr.join(' • ')
    if (_.has(formattedModules, group_by)) {
      formattedModules[group_by].push(module)
    } else {
      formattedModules[group_by] = [module]
    }
  })
  _.forOwn(formattedModules, (modules, group_by) => {
    groupByModules.push({
      group_by: group_by,
      modules: modules,
    })
  })
  return groupByModules
}

export const formatOrders = () => {}
